import { Dialog } from '@material-ui/core';
import {
    FunctionComponent,
    useContext,
    useState,
    useEffect
} from 'react';
import { ReactComponent as MobileIcon } from 'assets/images/mobile.svg';
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints';
import { ButtonCancel, ButtonsContainer, ModalContainer, useStyles } from 'components/shareable/Modal/styles';
import { Container } from 'styles/shared';
import { useHistory } from 'react-router-dom';
import { CardOption } from 'components/shareable/Modal/CardOption';
import SharedMusicViewSelector from 'pages/post-auth/MarketingPage/Components/SuperboostModal/Components/shared-music-selector-view';
import HeaderModal from 'pages/post-auth/MarketingPage/Components/HeaderModal';
import { v4 } from 'uuid';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import MusicSiteIcon from 'assets/images/websiteThemes/music-site.svg';
import DataCollectIcon from 'assets/images/websiteThemes/data-collector-site.svg';
import TourSiteIcon from 'assets/images/websiteThemes/tour.svg';
import PresaveIcon from 'assets/images/websiteThemes/presave-site.svg';
import { track } from 'analytics';
import { isValidURL } from 'utils'

// Add this function at the top of your file, outside of any component
function generateShortUniqueId(): string {
    const fullUuid = v4();
    return Buffer.from(fullUuid.replace(/-/g, ''), 'hex').toString('base64').replace(/[+/=]/g, '').substring(0, 12);
}

// Add this new enum
export enum SiteType {
    MUSIC = 'music',
    DATA_COLLECTOR = 'data_collector',
    TOUR = 'tour',
    PRE_SAVE = 'pre_save'
}

interface Props {
    open: boolean
    closeModal: () => void;
    isFirstSite?: boolean;
    titleText?: string;
    initialSiteType?: SiteType | null; // New prop
}

export enum Steps {
    SELECT_OPTION = "SELECT_OPTION",
    SELECT_RECORD = "SELECT_RECORD",
}

export const CreateSiteOptions = (props: {
    step: Steps;
    setStep: (step: Steps) => void;
}) => {
    const {
        step,
        setStep
    } = props

    const history = useHistory()
    const screenBreakpoints = useMediaBreakpoints();
    const { currentBrand } = useContext(CurrentBrandContext);
    const { mobileView } = screenBreakpoints;


    const handleBack = () => {
        setStep(Steps.SELECT_OPTION)
    }

    const handleChangeRecord = async (inputValue: string, slug: string | null) => {
        if (!slug) return
        if (isValidURL(inputValue)) {
            const url = encodeURIComponent(inputValue)
            history.push(`/website/record_streams/${slug}?url=${url}`)
        } else {
            history.push(`/website/record_streams/${slug}`)
        }
    }

    const cardOptions = [
        {
            thumbnail: MusicSiteIcon,
            text: "Promote your Songs, Projects, and Playlists",
            subtitle: "Showcase your latest releases with our music site - perfect for promoting your songs, albums, and playlists across streaming platforms.",
            onClick: () => {
                track('Start "New Website" flow', { type: 'music' })
                setStep(Steps.SELECT_RECORD)
            }
        },
        {
            thumbnail: DataCollectIcon,
            text: "Collect Fan Data",
            subtitle: "Build your email + phone number list with our data collection toolset - perfect for fan clubs, drops, RSVPs, and anything in between.",
            onClick: () => {
                track('Start "New Website" flow', { type: 'data collector' })
                const slug = generateShortUniqueId()
                history.push(`/website/data_collector/${slug}`)
            }
        },
        {
            thumbnail: TourSiteIcon, // TODO CONNER: placeholder icon from Megh
            text: "Promote Events",
            subtitle: "Showcase your latest event or tour with our events site.",
            onClick: () => {
                track('Start "New Website" flow', { type: 'tour' })
                const slug = generateShortUniqueId()
                history.push(`/website/tour/${slug}`)
            }
        },
        {
            thumbnail: PresaveIcon,
            text: "Create a Pre-Save",
            subtitle: "Build hype before your next release with Symphony's pre-save playbook.",
            onClick: () => {
                track('Start "New Website" flow', { type: 'pre-save' })
                history.push('/marketing/new/presave')
            }
        },

    ]

    return (
        <>
            {step === Steps.SELECT_OPTION && (
                <Container display='flex' gap="12px" flexDirection='column'>
                    {cardOptions.map((option, index) => (
                        <CardOption
                            key={index}
                            thumbnail={option.thumbnail}
                            text={option.text}
                            subtitle={option.subtitle}
                            onClick={option.onClick}
                        />
                    ))}
                </Container>
            )}
            {step === Steps.SELECT_RECORD && (
                <Container display='flex' gap="12px" flexDirection='column'>
                    <SharedMusicViewSelector
                        isSpotifyConnected={Boolean(currentBrand?.connections?.spotify_artist_page)}
                        currentBrand={currentBrand!}
                        letUserSearchSpotify
                        handleChangeRecord={handleChangeRecord}
                        options={['single', 'project', 'playlist']}
                    />
                    <ButtonsContainer
                        flexDirection={mobileView ? "column-reverse" : "row"}
                    >
                        <ButtonCancel
                            onClick={handleBack}
                            width={mobileView ? "100%" : "fit-content"}
                        >
                            Back
                        </ButtonCancel>
                    </ButtonsContainer>
                </Container>
            )}
        </>
    )
}

const CreateNewSiteModal: FunctionComponent<Props> = ({
    open,
    closeModal,
    isFirstSite,
    initialSiteType // New prop
}: Props) => {
    const classes = useStyles()
    const screenBreakpoints = useMediaBreakpoints();
    const { mobileView } = screenBreakpoints;
    const history = useHistory();

    const [step, setStep] = useState<Steps>(Steps.SELECT_OPTION);

    // Use useEffect to handle the initialSiteType
    useEffect(() => {
        if (initialSiteType) {
            handleInitialSiteType(initialSiteType);
        }
    }, [initialSiteType]);

    const handleInitialSiteType = (siteType: SiteType) => {
        switch (siteType) {
            case SiteType.MUSIC:
                setStep(Steps.SELECT_RECORD);
                break;
            case SiteType.DATA_COLLECTOR:
                track('Start "New Website" flow', { type: 'data collector' });
                const dataCollectorSlug = generateShortUniqueId();
                history.push(`/website/data_collector/${dataCollectorSlug}`);
                closeModal();
                break;
            case SiteType.TOUR:
                track('Start "New Website" flow', { type: 'tour' });
                const tourSlug = generateShortUniqueId();
                history.push(`/website/tour/${tourSlug}`);
                closeModal();
                break;
            case SiteType.PRE_SAVE:
                track('Start "New Website" flow', { type: 'pre-save' });
                history.push('/marketing/new/presave');
                closeModal();
                break;
        }
    };

    console.log('step', step)

    let titleText = 'Create a New Website'
    if (isFirstSite) {
        titleText = 'Create your first site'
    }

    if (step === Steps.SELECT_RECORD) {
        titleText = "Create a Music Site"
    }

    let subtitleText = "Select a website based on your marketing goal - every site is hyper-optimized to help you grow your audience and track your data."
    if (step === Steps.SELECT_RECORD) {
        subtitleText = "Search for your release or paste a Spotify URL to create your website."
    }

    return (
        <Dialog
            fullScreen={mobileView}
            open={open}
            classes={{
                root: classes.rootDialog,
            }}
        >
            <ModalContainer
                width={mobileView ? "auto" : "678px"}
                padding={mobileView ? "32px 24px" : "32px"}
                margin={mobileView ? "0px" : "auto"}
            >
                <HeaderModal
                    HeaderIcon={MobileIcon}
                    closeModal={closeModal}
                    title={titleText}
                    subtitle={subtitleText}
                />
                <CreateSiteOptions
                    step={step}
                    setStep={setStep}
                />
            </ModalContainer>
        </Dialog>
    )
}

export default CreateNewSiteModal;