import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import GeneralPurpleButton from '../PurpleButton';
import ClickableChip from 'pages/post-auth/MarketingPage/Components/CreateCampaignBadge';

interface CampaignItem {
    key: string;
    title: string;
    icon: string;
    link: string;
}

interface CustomCampaignSelectorProps {
    buttonText: string;
    onButtonClick: () => void;
    campaignItems: CampaignItem[];
    onCampaignItemClick: (campaignKey: string) => void;
    additionalItemsText?: string;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;

  @media (min-width: 801px) {
    flex-direction: row;
    align-items: center;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  padding: 0px 16px;

  @media (min-width: 801px) {
    width: auto;
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  align-items: center;
  gap: 8px;
  padding-top: 16px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (min-width: 801px) {
    padding-top: 0;
  }
`;

const PopularCampaignsText = styled.p`
  margin-left: 16px;
`;

const ChipContainer = styled.div`
  margin: 0 8px;
`;

const DefaultAdditionalActions: React.FC<CustomCampaignSelectorProps> = ({
    buttonText,
    onButtonClick,
    campaignItems,
    onCampaignItemClick,
    additionalItemsText,
}) => {
    return (
        <Container>
            <ButtonContainer>
                <GeneralPurpleButton fullWidthOnMobile onClick={onButtonClick}>
                    {buttonText}
                </GeneralPurpleButton>
            </ButtonContainer>
            <ItemsContainer>
                {additionalItemsText && <PopularCampaignsText>{additionalItemsText}</PopularCampaignsText>}
                {campaignItems.map((item) => (
                    <ChipContainer key={item.key}>
                        <Link
                            to={item.link}
                            onClick={() => onCampaignItemClick(item.key)}
                        >
                            <ClickableChip
                                onClick={() => {}}
                                campaignImage={item.icon}
                                campaignName={item.title}
                                campaignType="title"
                            />
                        </Link>
                    </ChipContainer>
                ))}
            </ItemsContainer>
        </Container>
    );
};

export default DefaultAdditionalActions;