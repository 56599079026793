import { WebsiteEditorCard, renderBorderSeparator } from "pages/post-auth/Website/styles";
import { useStyles } from "../../styles";
import ImagePalette from "pages/post-auth/Website/ImagePalette";
import { RECORD_STREAMS_TYPES } from 'pages/post-auth/Website/types';
import { checkIfIsProTier, objectToArrayInOrder } from "utils";
import {
  streamLinkOrientations,
  fontThemeStyles,
  ThemeStyle, StyleType,
  FontThemeStyle,
  musicThemeStyles,
  dataCollectorThemeStyles,
  tourThemeStyles,
  tourButtonStyles,
  FontThemeStyleType,
  THEME_STYLES,
} from "../../utils";
import DefaultWebsiteThemeIcon from "components/svg-icons/website/themes/default";
import clsx from "clsx";
import { Fragment, useContext } from "react";
import { WebsiteContext } from "../../hooks/WebsiteContext";
import { Select } from "@mui/material";
import { WEBSITE_TYPES, WebsiteType } from "components/shareable/ContentTypeBadge/website";
import { Body2, Caption, Subtitle1 } from "components/shareable/Typography";
import { EditorSectionContainer, WebsiteEditorSection } from "./styles";
import { Button, MenuItem } from "@material-ui/core";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { BasicWebsiteThemeIcon, BoxesWebsiteLayoutIcon, ClassicWebsiteThemeIcon, FullImageWebsiteThemeIcon, OneFieldWebsiteThemeIcon, StackedWebsiteLayoutIcon, TraditionalWebsiteLayoutIcon, VinylWebsiteThemeIcon } from "components/svg-icons/website/themes/icons";
import CloudinaryUploadWidget from "components/shareable/CloudinaryUploadWidget";
import { ReactComponent as EditIcon } from "../../../../../../../assets/images/edit-purple.svg";
import { SystemColors } from "types/globalStyles";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import GemIcon from "components/shareable/ProCallouts/GemIcon";

const { BASIC, ART, CLASSIC, FULL_IMAGE, FULL_VIDEO, SIMPLE, VINYL } = THEME_STYLES

interface Props {
  websiteType: WebsiteType
}

const ThemeBox = (props: { currentThemeStyle: ThemeStyle }) => {
  const { currentThemeStyle } = props
  const { themeStyle, setThemeStyle, setEmbedYt, youtubeVideoLink } = useContext(WebsiteContext);
  const classes = useStyles();
  const { name, title, isProOnly } = currentThemeStyle;

  const isThemeSelected = themeStyle === name;
  return (
    <div
      className={clsx(classes.themeBox, isThemeSelected && classes.themeBoxSelected)}
      onClick={() => {
        setThemeStyle(name)
        if (name !== ART && name !== CLASSIC) {
          setEmbedYt(false)
        } else {
          setEmbedYt(Boolean(youtubeVideoLink?.length))
        }
      }}
    >
      {(name === FULL_IMAGE || name === FULL_VIDEO) && (
        <FullImageWebsiteThemeIcon selected={isThemeSelected} />
      )}
      {name === VINYL && (
        <VinylWebsiteThemeIcon selected={isThemeSelected} />
      )}
      {name === ART && (
        <DefaultWebsiteThemeIcon selected={isThemeSelected} />
      )}
      {name === BASIC && (
        <BasicWebsiteThemeIcon selected={isThemeSelected} />
      )}
      {name === SIMPLE && (
        <OneFieldWebsiteThemeIcon selected={isThemeSelected} />
      )}
      {name === CLASSIC && (
        <ClassicWebsiteThemeIcon selected={isThemeSelected} />
      )}
      {title}
      {isProOnly && <GemIcon color='purple' />}
    </div>
  );
};

const FontIcon = (props: { font: string }) => {
  const { font } = props
  const classes = useStyles();
  return (
    <div className={classes.fontThemeStyleIcon} style={{ fontFamily: `'${font}'` }}>
      Aa
    </div>
  );
};

const FontBox = (props: { currentFontStyle: FontThemeStyle, index: number }) => {
  const { currentFontStyle, index } = props
  const { fontThemeStyle, setFontThemeStyle } = useContext(WebsiteContext);
  const classes = useStyles();
  const maxFonts = Object.keys(fontThemeStyles).length;
  const fontPosition = `${index + 1}/${maxFonts}`;

  const isSelected = fontThemeStyle === currentFontStyle.name;
  return (
    <div
      className={clsx(classes.themeBox, isSelected && classes.themeBoxSelected)}
      onClick={() => {
        setFontThemeStyle(currentFontStyle.name)
      }}
    >
      <FontIcon font={currentFontStyle.title} />
      <span>{fontPosition}</span>
      {currentFontStyle.isProOnly && <UpgradeToProChip
        type="gem"
        color="purple"
      />}
    </div>
  );
};

export const StyleSection = ({ websiteType }: Props) => {
  const classes = useStyles();
  const { currentBrand } = useContext(CurrentBrandContext);
  const isProUser = checkIfIsProTier(currentBrand);

  const {
    styleType, setStyleType,
    mainColor = '', setMainColor,
    recordSelected,
    secondaryColor = '', setSecondaryColor,
    bgType, setBgType,
    bgValue, setBgValue,
    themeStyle,
    fullVideoUrl, setFullVideoUrl,
    fontThemeStyle,
    customThumbnailUrl, setCustomThumbnailUrl,
    customThumbnailViewStyle, setCustomThumbnailViewStyle,
  } = useContext(WebsiteContext)

  const imageUrl = recordSelected?.thumbnail_url || currentBrand?.image || ''
  const musicThemeStylesToUse = musicThemeStyles
  if (recordSelected?.type === RECORD_STREAMS_TYPES.PLAYLIST) {
    delete musicThemeStylesToUse.vinyl
  }

  const LinkOrientationBox = (props: { currentStreamLinkOrientation: StyleType, websiteType: WebsiteType }) => {
    const { currentStreamLinkOrientation, websiteType } = props
    const isStyleType = styleType === currentStreamLinkOrientation.name
    if (websiteType === WEBSITE_TYPES.TOUR && currentStreamLinkOrientation.name === 'traditional') {
      return null
    }

    return (
      <div
        className={clsx(classes.themeBox, isStyleType && classes.themeBoxSelected)}
        onClick={() => setStyleType(currentStreamLinkOrientation.name)}
      >
        {currentStreamLinkOrientation.name === 'boxes' && (<BoxesWebsiteLayoutIcon selected={isStyleType} />)}
        {currentStreamLinkOrientation.name === 'stacked' && (<StackedWebsiteLayoutIcon selected={isStyleType} />)}
        {currentStreamLinkOrientation.name === 'traditional' && (<TraditionalWebsiteLayoutIcon selected={isStyleType} />)}
        {currentStreamLinkOrientation.title}
      </div>
    );
  };

  const UploadArtworkSection = ({ artworkUrl }: { artworkUrl: string }) => {
    const classes = useStyles();
    const buttonClassName = artworkUrl
      ? classes.changeButton
      : classes.uploadButton;

    return (
      <div className="flex">
        <Button className={buttonClassName} id="upload_video">
          {artworkUrl && (
            <video
              className={classes.artworkPreview}
              src={artworkUrl}
            />
          )}
          <Body2 color={SystemColors.WHITE_TEXT_COLOR}>{artworkUrl ? 'video.mp4' : 'Upload'}</Body2>
          {artworkUrl && <EditIcon style={{ width: 18, height: 18 }} />}
        </Button>
        <CloudinaryUploadWidget
          uploadButtonId="upload_video"
          resourceType="video"
          maxFiles={1}
          showUploadMoreButton={false}
          clientAllowedFormats={["mp4"]}
          onUploadAdded={(_fileId: string) => { }}
          onUploadSuccess={handleOnUploadVideoSuccess}
          onUploadAbortAll={() => { }}
          onUploadAbort={(_fileId: string) => { }}
          onCancel={(_info: Record<string, unknown>) => { }}
          maxFileSize={50000000}
        />
      </div>
    );
  };
  const UploadThumbnailSection = ({ thumbnailUrl, allowResizing = false }: { thumbnailUrl: string, allowResizing?: boolean }) => {
    const classes = useStyles();
    const buttonClassName = thumbnailUrl
      ? classes.changeButton
      : classes.uploadButton;

    return (
      <div className="flex flex-col">
        <div className="flex">
          <Button className={buttonClassName} id="upload_thumbnail">
            {customThumbnailUrl && (
              <img
                className={classes.artworkPreview}
                src={customThumbnailUrl}
              />
            )}
            <Body2 color={SystemColors.WHITE_TEXT_COLOR}>{customThumbnailUrl ? 'thumbnail.jpg' : 'Upload'}</Body2>
            {customThumbnailUrl && <EditIcon style={{ width: 18, height: 18 }} />}
          </Button>
          <CloudinaryUploadWidget
            uploadButtonId="upload_thumbnail"
            resourceType="image"
            maxFiles={1}
            showUploadMoreButton={false}
            clientAllowedFormats={["jpg", "jpeg", "png"]}
            onUploadAdded={(_fileId: string) => { }}
            onUploadSuccess={handleOnUploadThumbnailSuccess}
            onUploadAbortAll={() => { }}
            onUploadAbort={(_fileId: string) => { }}
            onCancel={(_info: Record<string, unknown>) => { }}
          />
        </div>
        {allowResizing && (
          <>
            <Select
              value={customThumbnailViewStyle}
              onChange={(e) => setCustomThumbnailViewStyle(e.target.value as 'cover' | 'contain')}
              className='mt-4 border h-8 w-full'
            >
              <MenuItem value='cover'>Stretch to Fit</MenuItem>
              <MenuItem value='contain'>Show Entire Image</MenuItem>
            </Select>
            <div className='text-xs text-gray-500 mt-2'>
              {customThumbnailViewStyle === 'contain'
                ? '* Your image will be shown with its full height + width ratio.'
                : '* Your image will be stretched to fill the entire image container.'}
            </div>
          </>
        )}
      </div>
    );
  };

  const handleOnUploadVideoSuccess = (file: { info: { secure_url: string } }) => {
    const artwork = file.info.secure_url;
    setFullVideoUrl(artwork)
  };

  const handleOnUploadThumbnailSuccess = (file: { info: { secure_url: string } }) => {
    const thumbnail = file.info.secure_url;
    setCustomThumbnailUrl(thumbnail)
  };

  let themeStylesToShow = null;
  switch (websiteType) {
    case WEBSITE_TYPES.MUSIC:
      themeStylesToShow = musicThemeStylesToUse;
      break;
    case WEBSITE_TYPES.DATA_COLLECTOR:
      themeStylesToShow = dataCollectorThemeStyles;
      break;
    case WEBSITE_TYPES.TOUR:
      themeStylesToShow = tourThemeStyles;
      break;
    default:
      themeStylesToShow = musicThemeStylesToUse;
  }
  type themeStylesType = keyof typeof themeStylesToShow

  return (
    <div
      className={classes.customizeWebsiteV2Variable}
      style={{ flexDirection: 'column' }}
    >
      <EditorSectionContainer>
        <WebsiteEditorSection>
          <Subtitle1>Theme</Subtitle1>
          <div className="flex gap-4 overflow-scroll">
            {objectToArrayInOrder(themeStylesToShow).map((ts, tsi) => {
              return <ThemeBox currentThemeStyle={ts} />
            })}
          </div>
          {(themeStylesToShow[themeStyle as themeStylesType].isProOnly && !isProUser) && (
            <Caption>You've selected a Pro-only template. You'll need to upgrade to Pro before publishing your site</Caption>
          )}
        </WebsiteEditorSection>
        {(websiteType === WEBSITE_TYPES.MUSIC || websiteType === WEBSITE_TYPES.TOUR) && (
          <>
            {renderBorderSeparator()}
            <WebsiteEditorSection>
              <Subtitle1>
                {websiteType === WEBSITE_TYPES.TOUR ? 'Dates Style' : 'Choose your link display'}
              </Subtitle1>
              <div className="flex gap-4 overflow-scroll">
                {objectToArrayInOrder(streamLinkOrientations).map(
                  (slo) => {
                    return <LinkOrientationBox currentStreamLinkOrientation={slo} websiteType={websiteType} />
                  }
                )}
              </div>
            </WebsiteEditorSection>
          </>
        )}
        {themeStyle === FULL_VIDEO && (
          <>
            {renderBorderSeparator()}
            <WebsiteEditorSection>
              <Subtitle1>Upload a video</Subtitle1>
              <Body2>
                Select the video you want to display. (50MB max)
              </Body2>
              <UploadArtworkSection artworkUrl={fullVideoUrl || ''} />
            </WebsiteEditorSection>
          </>
        )}
        {websiteType === WEBSITE_TYPES.DATA_COLLECTOR &&
          <>
            {renderBorderSeparator()}
            <WebsiteEditorSection>
              <Subtitle1>Cover</Subtitle1>
              <Body2>
                Select the artwork you want to display along with your data collector. We recommend using at least an image that's 1000x1000.
              </Body2>
              <UploadThumbnailSection thumbnailUrl={customThumbnailUrl || ''} />
            </WebsiteEditorSection>
          </>
        }
        {websiteType === WEBSITE_TYPES.TOUR &&
          <>
            {renderBorderSeparator()}
            <WebsiteEditorSection>
              <Subtitle1>Cover</Subtitle1>
              <Body2>
                Select the artwork you want to display along with your tour. We recommend using at least an image that's 1000x1000.
              </Body2>
              <UploadThumbnailSection thumbnailUrl={customThumbnailUrl || ''}
                allowResizing={themeStyle == BASIC} />
            </WebsiteEditorSection>
          </>
        }
        {renderBorderSeparator()}
        <WebsiteEditorSection>
          <Subtitle1>Primary Font</Subtitle1>
          <div className={classes.renderSelectorBoxRow}>
            {objectToArrayInOrder(fontThemeStyles).map((fs, fsi) => {
              return <FontBox currentFontStyle={fs} index={fsi} />
            })}
          </div>
          {Boolean(!isProUser && fontThemeStyles[fontThemeStyle as FontThemeStyleType].isProOnly) &&
            <Caption>You've selected a Pro-only font. You'll need to upgrade to Pro before publishing your site.</Caption>
          }
        </WebsiteEditorSection>
        {renderBorderSeparator()}
        <WebsiteEditorSection>
          <Subtitle1>Page Color</Subtitle1>
          <div
            className='palettePickerContainer'
            style={{ display: 'flex', alignItems: 'center', overflow: 'scroll', gap: 8 }}
          >
            <div
              className={classes.colorPickerContainer}
            >
              <input
                className={classes.colorPickerBox}
                type='color'
                id='bg-color'
                name='bgcolor'
                value={mainColor}
                onChange={(e) => setMainColor(e.target.value)}
              />
              <div>#</div>
              <input
                value={mainColor.replace('#', '')}
                className={classes.hexColorInput}
                onChange={(e) => setMainColor('#' + e.target.value)}
                maxLength={6}
              />
            </div>
            <ImagePalette
              imageUrl={customThumbnailUrl || imageUrl}
              selectedColor={mainColor}
              selectColor={(color) => {
                setMainColor(color);
              }}
              showBlackAndWhite
            />
          </div>
        </WebsiteEditorSection>
        {(styleType === 'boxes' || websiteType === WEBSITE_TYPES.DATA_COLLECTOR || websiteType === WEBSITE_TYPES.TOUR) && (
          <Fragment>
            {renderBorderSeparator()}
            <Subtitle1>Button Color</Subtitle1>
            <div
              className='palettePickerContainer'
              style={{ display: 'flex', alignItems: 'center', overflow: 'scroll', gap: 8 }}
            >
              <div
                className={classes.colorPickerContainer}
              >
                <input
                  className={classes.colorPickerBox}
                  type='color'
                  id='bg-color'
                  name='bgcolor'
                  value={secondaryColor}
                  onChange={(e) => setSecondaryColor(e.target.value)}
                />
                <div>#</div>
                <input
                  value={secondaryColor.replace('#', '')}
                  className={classes.hexColorInput}
                  onChange={(e) => setSecondaryColor('#' + e.target.value)}
                  maxLength={6}
                />
              </div>
              <ImagePalette
                imageUrl={customThumbnailUrl || imageUrl}
                selectedColor={secondaryColor}
                selectColor={(color) => {
                  setSecondaryColor(color);
                }}
                showBlackAndWhite
              />
            </div>
          </Fragment>
        )}
      </EditorSectionContainer>
      <WebsiteEditorCard title='Desktop Background'>
        <div className='text-xs text-gray-500 mb-2'>
          * Select the background you want your website to have on larger, desktop devices
        </div>
        <div className='mainBgTypeContainer flex flex-col gap-4'>
          <Select
            value={bgType}
            onChange={(e) => setBgType(e.target.value)}
            className='mainBgTypeDropdown border h-8 w-10/12 w-full'
          >
            <MenuItem value='color'>Color</MenuItem>
            <MenuItem value='artwork'>Artwork</MenuItem>
          </Select>
          <div className='py-2 flex items-center w-auto'>
            {bgType === 'color' ? (
              <div className={classes.colorPickerContainer}>
                <input
                  className={classes.colorPickerBox}
                  type='color'
                  id='bg-color'
                  name='bgcolor'
                  value={bgValue?.color}
                  onChange={(e) => setBgValue({
                    color: e.target.value,
                    artwork: bgValue?.artwork || 0,
                  })}
                />
                <div>{bgValue?.color}</div>
              </div>
            ) : (
              <p>Your artwork will be used as the background image.</p>
            )}
          </div>
        </div>
      </WebsiteEditorCard>
    </div>
  )
}
