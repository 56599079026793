import {
  FunctionComponent,
  SyntheticEvent,
  useState,
  useEffect,
  ChangeEventHandler,
  Fragment,
  useRef,
  MutableRefObject,
} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import TrashIcon from "assets/images/trash.svg";
import UploadIconPurple from "assets/images/upload-icon-purple.svg";
import useStyles, {
  MainContainer,
  ClickableLabel,
  UploadLabel,
  TrashCanIconContainer,
  Icon,
  HiddenInput,
  UploadLabelContainer,
  UploadIconContainer,
  ReloadIconContainer,
} from "./styles";
import FileIcon from "assets/images/file-icon.svg";
import ReloadIcon from "assets/images/reload.svg";
import { StyledLabel } from "../../styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const HIDDEN_INPUT_ID = "upload-csv-input-file";

interface Props {
  file?: File;
  maxWidth?: string;
  loading?: boolean;
  onUpload?: ChangeEventHandler<HTMLInputElement>;
  onDelete?: () => void;
  style?: CSSProperties;
}

const UploadInput: FunctionComponent<Props> = ({
  file,
  maxWidth,
  loading,
  onUpload,
  onDelete,
  style = {},
}: Props) => {
  const ref = useRef(null) as MutableRefObject<HTMLInputElement | null>;
  const classes = useStyles();
  const fileName = file?.name;
  const [clickedTrashIcon, setClickedTrashIcon] = useState(false);
  const label = loading ? "Uploading file..." : "Upload your file here...";
  const htmlFor = clickedTrashIcon || fileName ? undefined : HIDDEN_INPUT_ID;
  const cursor = fileName ? undefined : "pointer";

  const handleClickTrashCanIcon = (e: SyntheticEvent) => {
    const current = ref?.current;
    
    e?.stopPropagation();
    if (current) current.value = "";
    if (onDelete) onDelete();
    setClickedTrashIcon(true);
  };

  useEffect(() => {
    if (clickedTrashIcon) setClickedTrashIcon(false);
  }, [clickedTrashIcon]);

  return (
    <MainContainer {...{ maxWidth }} style={style}>
      <ClickableLabel {...{ htmlFor, cursor }}>
        <UploadIconContainer>
          {!loading && fileName && (
            <Icon width={24} height={24} alt="file-icon.svg" src={FileIcon} />
          )}
          {loading ? (
            <CircularProgress
              size={24}
              classes={{
                root: classes.root,
                colorPrimary: classes.colorPrimary,
              }}
            />
          ) : (
            <Fragment>
              {!fileName && (
                <Icon
                  cursor="pointer"
                  width={24}
                  height={24}
                  alt="upload-icon-purple.svg"
                  src={UploadIconPurple}
                />
              )}
            </Fragment>
          )}
        </UploadIconContainer>
        <UploadLabelContainer>
          {fileName ? (
            <StyledLabel>{fileName}</StyledLabel>
          ) : (
            <UploadLabel>{label}</UploadLabel>
          )}
        </UploadLabelContainer>
        {fileName && (
          <Fragment>
            <ReloadIconContainer htmlFor={HIDDEN_INPUT_ID}>
              <Icon
                cursor="pointer"
                width={24}
                height={24}
                alt="reload-icon.svg"
                src={ReloadIcon}
              />
            </ReloadIconContainer>
            <TrashCanIconContainer onClick={handleClickTrashCanIcon}>
              <Icon
                cursor="pointer"
                width={24}
                height={24}
                alt="trash-icon.svg"
                src={TrashIcon}
              />
            </TrashCanIconContainer>
          </Fragment>
        )}
      </ClickableLabel>
      <HiddenInput
        {...{ ref }}
        id={HIDDEN_INPUT_ID}
        onChange={onUpload}
        type="file"
        accept=".csv"
      />
    </MainContainer>
  );
};

export default UploadInput;
