import { Dialog, InputAdornment, TextField } from "@material-ui/core";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { SYMPHONY_WEBSITE_URL } from '../../../../../constants';
import HeaderModal from "pages/post-auth/MarketingPage/Components/HeaderModal";
import { useContext, useState } from "react";
import { DialogContainer, TextFieldContainer, useStyles } from "./styles";
import { Caption } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import LinkButton from "components/shareable/LinkButton";
import PrimaryButton from "components/shareable/PrimaryButton";
import { BrandContent } from "types/global";
import { ReactComponent as Globe } from "assets/images/globe.svg";
import { WebsiteContext } from "../CustomizeWebsite/hooks/WebsiteContext";
import { formatDataToPublish } from "./utils";
import { publishBrandContent } from "services/symphonyApi/brandContentService";
import { saveBrandWebsite } from "services/symphonyApi/websiteService";
import { WebsiteLink } from "../CustomizeWebsite/utils";
import { track } from "analytics";
import { EditWebsiteSlugInput } from "pages/post-auth/MarketingPage/Components/Modals/EditWebsiteSlugModal";

interface Props {
  open: boolean;
  brandContent: BrandContent;
  originalSlug: string;
  onPublished?: () => void;
  refreshSiteData: (newWebsiteSlug?: string) => void
  onClose: () => void;
}

export const PublishSiteModal = ({
  open,
  brandContent,
  originalSlug,
  onPublished,
  refreshSiteData,
  onClose,
}: Props) => {
  const {
    bgType,
    bgValue,
    customLinkOptions,
    customLinksAdded,
    embedYt,
    enableSocialIcons,
    fields,
    fontThemeStyle,
    isNewLink,
    isProject,
    mainColor,
    socialMediaIcons,
    recordSelected,
    removeSymphonyBrand,
    addAffiliateLinkToLogo,
    secondaryColor,
    selectedPreviewTrack,
    showRecordSelector,
    submitButtonText,
    showSecondaryLine,
    streamingLinks,
    styleType,
    subtitle,
    themeStyle,
    titleText,
    youtubeID,
    youtubeVideoLink,
    customThumbnailUrl,
    customThumbnailViewStyle,
    tourDates,
    hidePastTourDates,
  } = useContext(WebsiteContext);

  const { currentBrand } = useContext(CurrentBrandContext)
  const classes = useStyles()
  const prefixUrl = `${SYMPHONY_WEBSITE_URL}/${currentBrand?.slug}/`

  const [slug, setSlug] = useState<string>(originalSlug)
  const [loading, setLoading] = useState<boolean>(false)
  const [isValidSlug, setIsValidSlug] = useState<boolean>(true)
  const [alreadyInUse, setAlreadyInUse] = useState<boolean>(false)

  const SLUG_FULL_LENGTH = 30

  const handleSlugChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (alreadyInUse) setAlreadyInUse(false)
    const newSlug = event.target.value ? event.target.value.trim() : ''
    const isValid = validateSlug(newSlug)
    setSlug(newSlug)
    setIsValidSlug(isValid)
  }

  const validateSlug = (slug: string) => {
    const slugRegex = /^[a-zA-Z0-9-_]+$/;
    return Boolean(slug.trim() && slugRegex.test(slug.trim()) && slug.trim().length <= SLUG_FULL_LENGTH);
  }

  const resetToOriginalSlug = () => {
    setSlug(originalSlug);
    setIsValidSlug(true);
  }

  const handlePublishWebsite = async () => {
    setLoading(true)
    try {
      const contextData = {
        bgType,
        bgValue,
        customLinkOptions,
        customLinksAdded,
        embedYt,
        enableSocialIcons,
        fields,
        fontThemeStyle,
        isNewLink,
        isProject,
        mainColor,
        socialMediaIcons,
        recordSelected,
        removeSymphonyBrand,
        addAffiliateLinkToLogo,
        secondaryColor,
        selectedPreviewTrack,
        showRecordSelector,
        submitButtonText,
        showSecondaryLine,
        streamingLinks,
        styleType,
        subtitle,
        themeStyle,
        titleText,
        youtubeID,
        youtubeVideoLink,
        previewUrl: customThumbnailUrl || currentBrand?.image,
        customThumbnailViewStyle,
        tourDates,
        hidePastTourDates,
      }

      const dataFormatted = formatDataToPublish(contextData, brandContent, currentBrand)
      dataFormatted.url = `${currentBrand?.slug}-${slug}`;
      dataFormatted.slug = slug;

      let socialIcons = currentBrand?.website?.socialIcons || []
      if (enableSocialIcons && socialMediaIcons) {
        socialIcons = Object.entries(socialMediaIcons).map(([key, value]: [string, WebsiteLink]) => value as WebsiteLink)
      }

      const brandDataToUpdate = {
        buttonConfig: styleType,
        enableSocialIcons,
        removeSymphonyBrand,
        addAffiliateLinkToLogo,
        socialIcons,
      }

      const publishedContentResponse = await publishBrandContent(dataFormatted)

      await saveBrandWebsite(currentBrand?.slug || '', brandDataToUpdate)
      refreshSiteData(publishedContentResponse.data.slug);
      onPublished && onPublished();
      onClose();
    } catch (error: any) {
      if (error?.message?.length) {
        setAlreadyInUse(true)
        console.log('error', error)
      } else if (error?.response?.data?.message?.length) {
        setAlreadyInUse(true)
      }
    }
    setLoading(false)
    track('Published "New Website"', {
      type: brandContent.type,
      template: themeStyle,
      font: fontThemeStyle,
      linkStyle: styleType,
    })
  }

  return (
    <Dialog open={open} className={classes.dialog}>
      <DialogContainer>
        <HeaderModal
          title="Let's go live!"
          subtitle="You can customize your website URL slug before publishing."
          closeModal={onClose}
        />
        <TextFieldContainer>
          <EditWebsiteSlugInput
            slug={slug}
            prefixUrl={prefixUrl}
            handleSlugChange={handleSlugChange}
          />
          {!isValidSlug && (
            <Caption color={SystemColors.ERROR_COLOR}>
              Please keep your input between 1 and {SLUG_FULL_LENGTH} characters and only use letters, numbers, dashes (-), and underscores (_).
            </Caption>
          )}
          {alreadyInUse && (
            <Caption color={SystemColors.ERROR_COLOR}>
              It looks like you already have another website with this URL. Please use an entirely new URL to successfully update your website.
            </Caption>
          )}
          {slug !== originalSlug && (
            <LinkButton text="Reset to Original URL" onClick={resetToOriginalSlug} />
          )}
        </TextFieldContainer>
        <PrimaryButton
          text="Publish"
          onClick={handlePublishWebsite}
          width="100%"
          disabled={!isValidSlug || alreadyInUse || loading}
          loading={loading}
        />
      </DialogContainer>
    </Dialog>
  )
}
