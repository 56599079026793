import Axios from "helpers/Interceptor"
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

export default async function getWebsiteData(brandSlug: string, contentSlug?: string, startDate?: string | null, endDate?: string | null) {
    var url = contentSlug ?
        `/dashboard/brand/${brandSlug}/website/${contentSlug}`
        :
        `/dashboard/brand/${brandSlug}/website/overall`;

    const userTimezone = dayjs.tz.guess()

    const response = await Axios.get(url, {
        params: {
            startDate,
            endDate,
            timezone: userTimezone
        }
    });
    if (response.data?.data) {
        return response.data.data;
    }
}
