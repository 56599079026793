import React, { useContext, useEffect, useState, memo, useMemo, ChangeEvent, useCallback } from "react";
import { Body2, Body3, Caption, Subtitle2 } from "components/shareable/Typography";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Container, SHARED_WEBSITE_STYLES, StyledLabel, WebsiteEditorCard, Image } from "pages/post-auth/Website/styles"
import { useStyles } from "../../../styles";
import { Button, Dialog, Modal, Switch, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import Text from 'modules/Text';
import { WebsiteContext } from "../../../hooks/WebsiteContext";
import { checkIfIsProTier, deepCopy, isValidUrl } from "utils";
import SecondaryButton from "components/shareable/SecondaryButton";
import LinkButton from "components/shareable/LinkButton";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FieldsList } from '../../FieldsList';
import { clsx } from "clsx";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import SymphonyLogo from 'assets/images/symphonyLogo.svg';
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import GemIcon from "components/shareable/ProCallouts/GemIcon";
import { SystemColors } from "types/globalStyles";
import DollarSignIcon from "assets/images/gettingStarted/dollar_sign.svg"
import Intercom from "helpers/Intercom";
import Website from "pages/post-auth/Website";
import {
    websiteInputRow,
    websiteSubSectionContainer,
    tourDateListContainer,
    tourDateRow,
    tourDateDetail,
    tourDateDay,
    tourDateMonth,
    tourDateAddUploadContainer,
} from "../../../utils";
import ReactDatePicker from "react-datepicker";
import dayjs from "dayjs";
import { CustomDateSelector } from "pages/post-auth/DashboardModule/components/LargeGraphPopup";
import { sortBy } from 'lodash';
import { ModalContainer } from "components/shareable/Modal/styles";
import HeaderModal from "pages/post-auth/MarketingPage/Components/HeaderModal";
import PrimaryButton from "components/shareable/PrimaryButton";
import { ReactComponent as TrashIcon } from 'assets/images/trash.svg';
import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import UploadInput from "pages/post-auth/Fans/Components/UploadEmailsByCsv/Components/UploadInput";
import head from "lodash/head";
import { uploadCsv } from "pages/post-auth/Fans/api";
import { UploadEmailsByCsvContext } from "pages/post-auth/Fans/Data/UploadEmailsByCsvProvider";
import Axios from 'helpers/Interceptor';
import { findConfigFile } from "typescript";
import SymphonyTabs from "components/shareable/SymphonyTabs";
import { useTabs } from "react-headless-tabs";
import { CSSProperties } from "@material-ui/core/styles/withStyles"

//TODO CONNER THIS IS A NEW FILE
const initFieldItem = {
    id: "custom",
    name: "",
    required: false,
    priority: 0,
    reserved: false,
}

const fieldItems: Record<string, { key: string, title: string, label: string, isProOnly: boolean }> = {
    email_number: {
        key: "email_number",
        title: "Email - Number",
        label: "What's your email/number?",
        isProOnly: false,
    },
    name: {
        key: "name",
        title: "Name",
        label: "What's your name?",
        isProOnly: false,
    },
    age: {
        key: "age",
        title: "Age",
        label: "What's your age?",
        isProOnly: true,
    },
    email: {
        key: "email",
        title: "Email",
        label: "What's your email?",
        isProOnly: true,
    },
    phone_number: {
        key: "phone_number",
        title: "Phone Number",
        label: "What's your number?",
        isProOnly: true,
    },
    custom: {
        key: "custom",
        title: "Custom",
        label: '',
        isProOnly: true,
    }
}

const upgradeToProChip = () => <UpgradeToProChip
    type="gem"
    color="purple"
    height={20}
    width={20}
/>

export const TourSetupSection = () => {
    const classes = useStyles();
    const { currentBrand } = useContext(CurrentBrandContext)
    const { handleOpenModal: handleOpenUpgradeToProModal } = useContext(UpgradeModalContext);
    const sharedClasses = SHARED_WEBSITE_STYLES();
    const isProUser = checkIfIsProTier(currentBrand);

    const theme = useTheme()
    const setResponsiveView = useMediaQuery(theme.breakpoints.down(701))

    const {
        titleText, setTitleText,
        subtitle, setSubtitle,
        showSecondaryLine, setShowSecondaryLine,
        fields = [], setFields,
        submitButtonText, setSubmitButtonText,
        removeSymphonyBrand, setRemoveSymphonyBrand,
        addAffiliateLinkToLogo, setAddAffiliateLinkToLogo,
        tourExternalUrl, setTourExternalUrl,
        tourDates, setTourDates,
        hidePastTourDates, setHidePastTourDates,
    } = useContext(WebsiteContext)

    const { upload, saveUpload } = useContext(UploadEmailsByCsvContext);

    const [cityText, setCityText] = useState<string>('')
    const [countryText, setCountryText] = useState<string>('')
    const [currentDate, setCurrentDate] = useState<string>(dayjs().format("YYYY-MM-DD"));
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false)
    const [isEditTourDate, setIsEditTourDate] = useState<boolean>(false)
    const [isPreSaleDatePickerOpen, setIsPreSaleDatePickerOpen] = useState<boolean>(false);
    const [isPreSalePeriod, setIsPreSalePeriod] = useState<boolean>(false)
    const [isShowSoldOut, setIsShowSoldOut] = useState<boolean>(false)
    const [isTourDateModalOpen, setIsTourDateModalOpen] = useState<boolean>(false)
    const [preSaleDate, setPreSaleDate] = useState<string>(dayjs().format("YYYY-MM-DD"));
    const [regionText, setRegionText] = useState<string>('')
    const [ticketUrlText, setTicketUrlText] = useState<string>('')
    const [buttonLinkText, setButtonLinkText] = useState<string>('Buy')
    const [tourIndex, setTourIndex] = useState<number>(-1)
    const [venueText, setVenueText] = useState<string>('')

    const [selectedCsv, setSelectedCsv] = useState<File | undefined>(undefined);
    const [uploadingCsv, setUploadingCsv] = useState(false);
    const [errorCsv, setErrorCsv] = useState<string>('');

    const [preSaleButtonText, setPreSaleButtonText] = useState<string>('');
    const [preSaleAction, setPreSaleAction] = useState<'collect' | 'link'>('collect');
    const [preSaleCustomLink, setPreSaleLinkText] = useState<string>('');

    const [googleSearchResults, setGoogleSearchResults] = useState<any[]>([]);
    const [selectedGoogleItem, setSelectedGoogleItem] = useState<any>(null);

    const [selectedTab, setSelectedTab] = useTabs(['event_details', 'date_customization']);

    const purpleRadioStyle = {
        accentColor: '#8800FF', // This sets the color of the radio button when checked
    };

    const [venueInputValue, setVenueInputValue] = useState('');
    const [showAutocomplete, setShowAutocomplete] = useState(false);
    const [selectedVenue, setSelectedVenue] = useState<{ name: string } | null>(null);

    const [ticketUrlError, setTicketUrlError] = useState<string>('');

    // New function to handle venue selection
    const handleVenueSelection = (newValue: any) => {
        if (newValue) {
            setVenueText(newValue.name || '');
            setVenueInputValue(newValue.name || '');
            setCityText(newValue.city || '');
            setRegionText(newValue.state || '');
            setCountryText(newValue.country || '');
            setSelectedGoogleItem(newValue);
            setSelectedVenue({ name: newValue.name || '' });
        } else {
            setVenueText('');
            setVenueInputValue('');
            setSelectedGoogleItem(null);
            setSelectedVenue(null);
        }
    };

    // Updated handleVenueInputChange function
    const handleVenueInputChange = async (query: string) => {
        setVenueInputValue(query);
        setVenueText(query);

        if (query.length >= 2) {
            const results = await searchItems(query);
            if (results.length > 0) {
                setGoogleSearchResults([...results]);
                setShowAutocomplete(true);
            } else {
                setGoogleSearchResults([]);
                setShowAutocomplete(false);
            }
        } else {
            setGoogleSearchResults([]);
            setShowAutocomplete(false);
        }
    };

    useEffect(() => {
        if (isTourDateModalOpen) return;

        setCityText('');
        setCountryText('');
        setCurrentDate(dayjs().format("YYYY-MM-DD"));
        setIsPreSalePeriod(false);
        setIsShowSoldOut(false);
        setPreSaleDate(dayjs().format("YYYY-MM-DD"));
        setRegionText('');
        setTicketUrlText('');
        setButtonLinkText('Buy');
        setVenueText('');
        setVenueInputValue('');
        setPreSaleButtonText('');
        setPreSaleAction('collect');
        setPreSaleLinkText('');
        setSelectedGoogleItem(null);
        setGoogleSearchResults([]);
    }, [isTourDateModalOpen]);

    const handleEditTourDate = (index: number) => {
        setIsEditTourDate(true);
        setIsTourDateModalOpen(true);
        setTourIndex(index);

        const tourDate = tourDates[index];
        setCityText(tourDate.city);
        setCountryText(tourDate.country);
        setCurrentDate(tourDate.date);
        setIsPreSalePeriod(tourDate.isPreSalePeriod);
        setIsShowSoldOut(tourDate.isSoldOut);
        setPreSaleDate(tourDate.preSaleDate);
        setRegionText(tourDate.region);
        setTicketUrlText(tourDate.ticketUrl);
        setButtonLinkText(tourDate.buttonLinkText);
        setVenueText(tourDate.venue);
        setVenueInputValue(tourDate.venue);
        setSelectedVenue({ name: tourDate.venue });
        setPreSaleButtonText(tourDate.preSaleButtonText);
        setPreSaleAction(tourDate.preSaleAction);

        setPreSaleLinkText(tourDate.preSaleCustomLink);

        // Reset the selected Google item and search results
        setSelectedGoogleItem(null);
        setGoogleSearchResults([]);
    };

    const searchItems = async (query: string) => {
        if (query.length < 2) return [];

        try {
            const response = await Axios.get(`/brand/${currentBrand?.slug}/google/autocomplete?q=${query}`);
            return response.data.results;
        } catch (error) {
            console.error('Search error:', error);
            return [];
        }
    };



    const handleDeleteTourDate = (index: number) => {
        const newTourDates = deepCopy(tourDates)
        newTourDates.splice(index, 1)
        setTourDates(newTourDates)
    }


    const createNewTourDate = () => {
        const tourDate = {
            city: cityText,
            country: countryText,
            date: currentDate,
            isPreSalePeriod: isPreSalePeriod,
            isSoldOut: isShowSoldOut,
            preSaleDate: preSaleDate,
            region: regionText,
            ticketUrl: ticketUrlText,
            buttonLinkText,
            venue: venueText,
            preSaleButtonText: preSaleButtonText,
            preSaleAction,
            preSaleCustomLink: preSaleCustomLink,
        }

        return tourDate;
    }

    const handleOpenTourDateModal = ({ isEdit }: { isEdit: boolean }) => {
        setIsTourDateModalOpen(true);
        setIsEditTourDate(isEdit);
    }

    const sortTourDates = (tourDatesLocal: any[]) => {
        const sortedTourDates = sortBy(tourDatesLocal, (tourDate) => new Date(tourDate.date));
        return sortedTourDates;
    }

    const handleAddEditTourDate = (tourIndex: number) => {
        const isValid = () => {
            if (!currentDate || (!venueText && !cityText)) {
                return false;
            }
            if (!ticketUrlText) {
                if (isPreSalePeriod) {
                    if (preSaleAction === 'collect' && !preSaleDate) {
                        return false;
                    }
                    if (preSaleAction === 'link' && !preSaleCustomLink) {
                        return false;
                    }
                } else {
                    return false;
                }
            }
            if (ticketUrlError) {
                return false;
            }
            return true;
        };

        if (!isValid()) {
            return;
        }

        const tourDate = createNewTourDate()

        if (isEditTourDate) {
            let newTourDates = deepCopy(tourDates)
            newTourDates[tourIndex] = tourDate
            newTourDates = sortTourDates(newTourDates)
            setTourDates(newTourDates)
        } else {
            let newTourDates = [...tourDates, tourDate];
            newTourDates = sortTourDates(newTourDates);
            setTourDates(newTourDates);
        }

        setIsTourDateModalOpen(false)
    }

    const transformTourDatesFromCsv = (tourDatesFromCsv: any[]) => {
        const newTourDates = tourDatesFromCsv.map((tourDate) => {
            return {
                city: tourDate.city || '',
                country: tourDate.country || '',
                date: tourDate.date || null,
                displayName: '',
                isPreSalePeriod: false,
                isSoldOut: tourDate.soldout || false,
                preSaleDate: null,
                region: tourDate.region || '',
                ticketUrl: tourDate.ticketUrl || '',
                venue: tourDate.venue || '',
                preSaleButtonText: '',
                preSaleAction: 'collect',
                preSaleCustomLink: '',
            }
        })
        setTourDates(newTourDates)
    }

    const handleRemoveSymphonyBrand = (
        _e: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => setRemoveSymphonyBrand(!checked);

    const handleUploadCsv = async (e: ChangeEvent<HTMLInputElement>) => {
        try {
            const files = e.target.files;
            const csv = head(files);

            if (csv) {
                // Check file size (5MB = 5 * 1024 * 1024 bytes)
                if (csv.size > 5 * 1024 * 1024) {
                    setErrorCsv('File size limit is 5MB');
                    return;
                }

                const formData = new FormData();
                setErrorCsv('');
                setUploadingCsv(true);
                formData.append("csv", csv);

                const response = await Axios.post(`/website/csv/tour-dates`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                const data = response.data.data;
                const tourDatesFromCsv = data.tour_dates.dateArray;
                const error = response.data.error;

                if (!error) {
                    setSelectedCsv(csv);
                    transformTourDatesFromCsv(tourDatesFromCsv);
                } else {
                    setErrorCsv('There was an error uploading your file');
                }
            }
        } catch (error) {
            setErrorCsv('An error occurred while uploading the file');
            return { data: null, error };
        } finally {
            setUploadingCsv(false);
        }
    };

    const handleDeleteCsv = () => {
        saveUpload({ ...upload, csv: undefined });
        setSelectedCsv(undefined);
    };

    const setIsShowSoldOutInRow = (isSoldOut: boolean, index: number) => {
        const newTourDates = deepCopy(tourDates)
        newTourDates[index].isSoldOut = isSoldOut
        setTourDates(newTourDates)
    }

    const renderAddTourDateButton = () => {
        let addDateButtonStyle: CSSProperties = {
            marginRight: tourDates.length ? 0 : 'auto',
            marginLeft: 'auto',
        }

        if (setResponsiveView) {
            addDateButtonStyle = {
                marginLeft: '0px',
                marginTop: '10px',
                marginBottom: '10px',
            }
        }

        return (
            <SecondaryButton
                className="addDateButton"
                text='Add a date'
                height='40px'
                onClick={() => handleOpenTourDateModal({ isEdit: false })}
                style={addDateButtonStyle as CSSProperties}
            />
        )
    }

    const renderUploadCsvButton = () => {
        return (
            <UploadInput
                style={{ marginTop: 0 }}
                file={selectedCsv}
                maxWidth={'380px'}
                loading={uploadingCsv}
                onUpload={handleUploadCsv}
                onDelete={handleDeleteCsv}
            />
        )
    }

    const renderTourDateRow = ({
        tourDate,
        index,
    }: {
        tourDate: any;
        index: number;
    }) => {
        const date = tourDate.date;
        const day = dayjs(date).format('DD')
        const month = dayjs(date).format('MMM')
        const isPastDate = dayjs(date).isBefore(dayjs().subtract(1, 'day'), 'day');

        const truncateVenue = (venue: string) => {
            if (setResponsiveView && venue.length > 16) {
                return venue.slice(0, 16) + '...';
            }
            return venue;
        };

        const renderPreSaleInfo = () => {
            if (tourDate.isPreSalePeriod && !tourDate.isSoldOut) {
                const preSaleEmoji = tourDate.preSaleAction === 'collect' ? '📧' : '🔗';
                const preSaleAction = tourDate.preSaleAction === 'collect'
                    ? 'Collecting Emails'
                    : 'Custom Link';
                const preSaleText = `${preSaleEmoji} Pre-Sale: ${preSaleAction}`;

                if (tourDate.preSaleDate) {
                    if (dayjs(tourDate.preSaleDate).isAfter(dayjs())) {
                        return `${preSaleText} until ${dayjs(tourDate.preSaleDate).format('MM/DD')}`;
                    } else if (!tourDate.ticketUrl) {
                        return `${preSaleText} - Missing ticket link`;
                    }
                }

                return preSaleText;
            }
            return null;
        };

        const renderTicketInfo = () => {
            if (tourDate.isPreSalePeriod) {
                return renderPreSaleInfo();
            }

            if (tourDate.isSoldOut) {
                return "Tickets marked sold out";
            }

            if (tourDate.ticketUrl && !isPastDate) {
                return "Tickets on sale";
            }

            if (isPastDate) {
                return null
            }

            return "No ticket link entered.";
        };

        return (
            <div className={`tourDateRow-${index}`} style={{ ...tourDateRow, justifyContent: 'space-between' }}>
                <div className="tourDateDetail" style={tourDateDetail}>
                    <div style={tourDateMonth}>
                        {month}
                    </div>
                    <div className="previewTourDateDay" style={tourDateDay}>
                        {day}
                    </div>
                </div>

                <div style={{ flex: 1, minWidth: 0, marginRight: 16 }}>
                    {isPastDate && (
                        <div style={{
                            fontSize: '12px',
                            color: SystemColors.WARNING_COLOR,
                            marginBottom: '2px'
                        }}>
                            (Past Date)
                        </div>
                    )}
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {truncateVenue(tourDate.venue)}
                    </div>
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: SystemColors.SECONDARY_TEXT_COLOR }}>
                        {[tourDate.city, tourDate.region].filter(Boolean).join(', ')}
                    </div>
                    {(renderPreSaleInfo() || renderTicketInfo()) && (
                        <div style={{
                            fontSize: '12px',
                            color: SystemColors.SECONDARY_TEXT_COLOR,
                            marginTop: '2px'
                        }}>
                            {renderPreSaleInfo() || renderTicketInfo()}
                        </div>
                    )}
                </div>

                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    {!setResponsiveView && (
                        <div className={classes.secondaryTextSection} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                            <Switch
                                checked={tourDate.isSoldOut}
                                onChange={(e: any) => setIsShowSoldOutInRow(e.target.checked, index)}
                                className={clsx(tourDate.isSoldOut && classes.switchEnabled)}
                            />
                            <div className='addSecondaryText' style={Text.secondary}>
                                Sold Out
                            </div>
                        </div>
                    )}
                    <EditIcon
                        className="editTourDate"
                        cursor='pointer'
                        onClick={() => handleEditTourDate(index)}
                        color={SystemColors.SECONDARY_TEXT_COLOR}
                        style={{ flexShrink: 0 }}
                    />
                    <TrashIcon
                        className="trashTourDate"
                        cursor='pointer'
                        onClick={() => handleDeleteTourDate(index)}
                        color={SystemColors.SECONDARY_TEXT_COLOR}
                        style={{ flexShrink: 0 }}
                    />
                </div>
            </div>
        );
    }

    const renderTourDateList = () => {
        return (
            <div style={tourDateListContainer}>
                {tourDates.map((tourDate, index) => (
                    renderTourDateRow({ tourDate, index })
                ))}
            </div>
        );
    }

    const TourReactDatePicker: React.FC = () => {
        return (
            <ReactDatePicker
                filterDate={(date: Date) => {
                    return dayjs(date).isAfter(dayjs(), 'day') || dayjs(date).isSame(dayjs(), 'day');
                }}
                selected={currentDate ? dayjs(currentDate).toDate() : null}
                onChange={(date) => {
                    if (date) {
                        const newDate = dayjs(date).format("YYYY-MM-DD");
                        setCurrentDate(newDate);
                    }
                }}
                customInput={<input style={Text.input} type='text' placeholder='Event Date' />}
                onCalendarOpen={() => setIsDatePickerOpen(true)}
                onCalendarClose={() => setIsDatePickerOpen(false)}
            />
        )
    }

    const PreSaleDatePicker: React.FC = () => {
        return (
            <ReactDatePicker
                filterDate={(date: Date) => {
                    return dayjs(date).isAfter(dayjs(), 'day') || dayjs(date).isSame(dayjs(), 'day');
                }}
                selected={preSaleDate ? dayjs(preSaleDate).toDate() : null}
                onChange={(date) => {
                    if (date) {
                        const newDate = dayjs(date).format("YYYY-MM-DD");
                        setPreSaleDate(newDate);
                    }
                }}
                customInput={
                    <input
                        style={Text.input}
                        type='text'
                        placeholder='Select a date'
                        value={preSaleDate ? dayjs(preSaleDate).format("MM/DD/YYYY") : ''}
                    />
                }
                onCalendarOpen={() => setIsPreSaleDatePickerOpen(true)}
                onCalendarClose={() => setIsPreSaleDatePickerOpen(false)}
            />
        )
    }
    const renderSaveUpdateButton = () => {
        const isDisabled = () => {
            if (!currentDate || (!venueText && !cityText)) {
                return true;
            }
            if (!ticketUrlText) {
                if (isPreSalePeriod) {
                    if (preSaleAction === 'collect' && !preSaleDate) {
                        return true;
                    }
                    if (preSaleAction === 'link' && !preSaleCustomLink) {
                        return true;
                    }
                } else {
                    return true;
                }
            }
            if (ticketUrlError) {
                return true;
            }
            return false;
        };

        const getMissingFieldsMessage = () => {
            const missingFields = [];
            if (!currentDate) missingFields.push('Date');
            if (!venueText && !cityText) missingFields.push('Venue or City');
            if (!ticketUrlText && !isPreSalePeriod) missingFields.push('Ticket Link');
            if (isPreSalePeriod) {
                if (preSaleAction === 'collect' && !preSaleDate) missingFields.push('Pre-sale Date');
                if (preSaleAction === 'link' && !preSaleCustomLink) missingFields.push('Pre-sale Link');
            }

            if (missingFields.length === 0) return '';
            return `Please fill in the following required fields: ${missingFields.join(', ')}`;
        };

        return (
            <div
                style={{
                    position: 'sticky',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: '100%',
                    paddingTop: 16,
                    paddingBottom: 16,
                    backgroundColor: SystemColors.PAPER_CONTAINER,
                    borderTop: `1px solid ${SystemColors.DIVIDER_LINE}`,
                }}
            >
                {isDisabled() && (
                    <Container padding="8px 16px" display="flex" flexDirection="column" alignItems="center" >
                        <Body2 color={SystemColors.WARNING_COLOR} style={{ textAlign: 'center', marginBottom: '4px' }}>
                            {getMissingFieldsMessage()}
                        </Body2>
                        {!ticketUrlText && !isPreSalePeriod && (
                            <Body2 color={SystemColors.WARNING_COLOR} style={{ textAlign: 'center' }}>
                                You can either add a ticket link or set up a pre-sale.
                            </Body2>
                        )}
                    </Container>
                )}
                <PrimaryButton
                    text={isEditTourDate ? 'Update' : 'Save'}
                    onClick={() => handleAddEditTourDate(tourIndex)}
                    width='100%'
                    className='addTourDateButton'
                    disabled={isDisabled()}
                />
            </div>
        )
    }

    const renderEventDetailsTab = () => {
        const validateTicketUrl = (url: string) => {
            if (url && !isValidUrl(url)) {
                setTicketUrlError('Please enter a valid URL (e.g., https://example.com)');
            } else {
                setTicketUrlError('');
            }
        };

        return (
            <Container display="flex" flexDirection="column" gap="24px">
                <Container display="flex" flexDirection="column" gap="4px">
                    <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                        Event Date
                    </Body2>
                    <Container marginTop="8px">
                        <TourReactDatePicker />
                    </Container>
                </Container>

                <Container display="flex" flexDirection="column" gap="4px">
                    <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                        Ticket Link
                    </Body2>
                    <Container marginTop="8px">
                        <input
                            style={{
                                ...Text.input,
                                borderColor: ticketUrlError ? SystemColors.ERROR_COLOR : undefined,
                            }}
                            placeholder='https://ticketmaster.com/xyz'
                            value={ticketUrlText}
                            onChange={(e) => {
                                setTicketUrlText(e.target.value);
                                validateTicketUrl(e.target.value);
                            }}
                            onBlur={() => validateTicketUrl(ticketUrlText)}
                        />
                        {ticketUrlError && (
                            <Caption color={SystemColors.ERROR_COLOR} style={{ marginTop: '4px' }}>
                                {ticketUrlError}
                            </Caption>
                        )}
                    </Container>
                </Container>

                <Container display="flex" flexDirection="column" gap="4px">
                    <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                        Action Button Text
                    </Body2>
                    <Container marginTop="8px">
                        <input
                            style={Text.input}
                            placeholder='Buy Now'
                            value={buttonLinkText}
                            onChange={(e) => setButtonLinkText(e.target.value)}
                            maxLength={30}
                        />
                    </Container>
                </Container>

                <Container display="flex" flexDirection="column" gap="4px">
                    <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                        Location
                    </Body2>
                    <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                        Enter any or all of the location details around your event - we'll automatically format it. A venue or city is required.
                    </Caption>
                    <Container display="flex" flexDirection="column" gap="8px" marginTop="8px">
                        <Autocomplete
                            options={googleSearchResults}
                            getOptionLabel={(option: any) => option.name || ''}
                            renderOption={(props, option: any) => {
                                const { id, name, city, state, country, description, fullDescription } = props;
                                let locationString = '';
                                if (city) locationString += city;
                                if (state) locationString += (locationString ? ', ' : '') + state;
                                if (country) locationString += (locationString ? ', ' : '') + country;

                                return (
                                    <li {...props}>
                                        <div style={{ fontFamily: 'DIN' }}>
                                            {name && <div>{name}</div>}
                                            {locationString && (
                                                <div style={{ fontSize: '0.8em', color: 'gray' }}>
                                                    {locationString}
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                );
                            }}
                            onChange={(event, newValue: any) => handleVenueSelection(newValue)}
                            inputValue={venueInputValue}
                            onInputChange={(event, newInputValue) => handleVenueInputChange(newInputValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder='Venue Name'
                                    InputProps={{
                                        ...params.InputProps,
                                        style: {
                                            ...Text.input,
                                            padding: 0,
                                            fontFamily: 'DIN',
                                        },
                                        disableUnderline: true
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: {
                                            ...Text.input,
                                            fontFamily: 'DIN',
                                        },
                                    }}
                                />
                            )}
                            freeSolo
                            value={selectedVenue}
                            open={showAutocomplete}
                            onClose={() => setShowAutocomplete(false)}
                            filterOptions={(x) => x}
                        />
                        <input
                            style={Text.input}
                            placeholder='City'
                            value={cityText}
                            onChange={(e) => setCityText(e.target.value)}
                        />
                        <input
                            style={Text.input}
                            placeholder='State / Region'
                            value={regionText}
                            onChange={(e) => setRegionText(e.target.value)}
                        />
                        <input
                            style={Text.input}
                            placeholder='Country'
                            value={countryText}
                            onChange={(e) => setCountryText(e.target.value)}
                        />
                    </Container>
                </Container>

                <Container display="flex" alignItems="center" gap="8px">
                    <Switch
                        checked={isShowSoldOut}
                        onChange={(e: any) => setIsShowSoldOut(e.target.checked)}
                        className={clsx(isShowSoldOut && classes.switchEnabled)}
                    />
                    <div className='addSecondaryText' style={Text.secondary}>
                        Mark as Sold Out
                    </div>
                </Container>

                {renderSaveUpdateButton()}

                <div style={{ color: 'red', textAlign: 'center' }}>
                    {errorMessage}
                </div>
            </Container>
        )
    }

    const renderDateCustomizationTab = () => {
        return (
            <Container display="flex" flexDirection="column" gap="24px">
                <Container display="flex" alignItems="center" gap="8px">
                    <Switch
                        checked={isPreSalePeriod}
                        onChange={(e: any) => setIsPreSalePeriod(e.target.checked)}
                        className={clsx(isPreSalePeriod && classes.switchEnabled)}
                    />
                    <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                        Add Pre-sale Period
                    </Body2>
                </Container>

                {isPreSalePeriod && (
                    <>
                        <Container display="flex" flexDirection="column" gap="4px">
                            <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                                Pre-sale Actions
                            </Body2>
                            <Container display="flex" flexDirection="column" gap="8px" marginTop="8px">
                                <div className={classes.radioButtonContainer}>
                                    <input
                                        type="radio"
                                        id="collect"
                                        name="preSaleAction"
                                        value="collect"
                                        checked={preSaleAction === 'collect'}
                                        onChange={() => setPreSaleAction('collect')}
                                        style={purpleRadioStyle}
                                    />
                                    <label htmlFor="collect" style={{ color: SystemColors.SECONDARY_TEXT_COLOR }}>Collect emails & numbers</label>
                                </div>
                                <div className={classes.radioButtonContainer}>
                                    <input
                                        type="radio"
                                        id="link"
                                        name="preSaleAction"
                                        value="link"
                                        checked={preSaleAction === 'link'}
                                        onChange={() => setPreSaleAction('link')}
                                        style={purpleRadioStyle}
                                    />
                                    <label htmlFor="link" style={{ color: SystemColors.SECONDARY_TEXT_COLOR }}>Open a custom link</label>
                                </div>
                            </Container>
                        </Container>

                        {preSaleAction === 'link' && (
                            <Container display="flex" flexDirection="column" gap="4px">
                                <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                                    Custom Link
                                </Body2>
                                <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                                    When a fan clicks on your pre-sale button, they'll be sent to this link
                                </Caption>
                                <Container marginTop="8px">
                                    <input
                                        style={Text.input}
                                        placeholder='Enter custom link'
                                        value={preSaleCustomLink}
                                        onChange={(e) => setPreSaleLinkText(e.target.value)}
                                    />
                                </Container>
                            </Container>
                        )}

                        <Container display="flex" flexDirection="column" gap="4px">
                            <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                                Pre-sale End Date
                            </Body2>
                            <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                                Once your pre-sale date passes, your ticket link will be shown to fans. If your ticket link is empty, we'll continue showing pre-sale until you enter it in.
                            </Caption>
                            <Container marginTop="8px">
                                <PreSaleDatePicker />
                            </Container>
                        </Container>

                        <Container display="flex" flexDirection="column" gap="4px">
                            <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                                Pre-sale Button Text
                            </Body2>
                            <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                                Customize pre-sale button text (max 10 characters)
                            </Caption>
                            <Container marginTop="8px">
                                <input
                                    style={Text.input}
                                    placeholder='Signup'
                                    value={preSaleButtonText}
                                    onChange={(e) => setPreSaleButtonText(e.target.value.slice(0, 10))}
                                    maxLength={10}
                                />
                            </Container>
                        </Container>
                    </>
                )}

                {renderSaveUpdateButton()}
            </Container>
        )
    }

    const renderTourDateModal = () => {
        return (
            <Dialog
                open={isTourDateModalOpen}
                onClose={() => setIsTourDateModalOpen(false)}
                title="Add a tour date"
                maxWidth="md"
            >
                <ModalContainer
                    width={setResponsiveView ? "auto" : "678px"}
                    padding={setResponsiveView ? "32px 24px" : "32px"}
                >
                    <HeaderModal
                        closeModal={() => setIsTourDateModalOpen(false)}
                        title="Add an Event"
                        subtitle="Add details for an event to display on your website. This can be a one-off event or a tour stop."
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', position: 'relative' }}>
                        <Container borderRadius="12px" backgroundColor={SystemColors.PAPER_CONTAINER} display="flex" alignItems="center" justifyContent="space-between">
                            <SymphonyTabs
                                data={[
                                    { label: 'Event Details', value: 'event_details' },
                                    { label: 'Date Customization', value: 'date_customization' },
                                ]}
                                selectedValue={selectedTab!}
                                onChange={(newValue: string) => setSelectedTab(newValue as 'event_details' | 'date_customization')}
                                gap={16}
                                tabContainerStyle={{ paddingRight: 0, paddingLeft: 0 }}
                                tabContentStyle={{ paddingRight: 0, paddingLeft: 0 }}
                            />
                        </Container>
                        {selectedTab === 'event_details' &&
                            renderEventDetailsTab()
                        }
                        {selectedTab === 'date_customization' &&
                            renderDateCustomizationTab()
                        }
                    </div>
                </ModalContainer>
            </Dialog>
        );
    }

    return (
        <div className={classes.customizeWebsiteV2Variable}>
            <div className='w-full flex flex-col gap-4'>
                <WebsiteEditorCard title='Title' className="tourTitle">
                    <input
                        style={Text.input}
                        type='text'
                        value={titleText}
                        placeholder='Title'
                        onChange={(e) => setTitleText(e.target.value)}
                    />
                    <div className={classes.secondaryTextSection}>
                        <Switch
                            checked={showSecondaryLine}
                            onChange={(e: any) => setShowSecondaryLine(e.target.checked)}
                            className={clsx(showSecondaryLine && classes.switchEnabled)}
                        />
                        <div className='addSecondaryText' style={Text.secondary}>
                            Add secondary line
                        </div>
                    </div>
                    {showSecondaryLine && (
                        <input
                            style={Text.input}
                            type='text'
                            value={subtitle}
                            placeholder='Secondary line'
                            onChange={(e) => setSubtitle(e.target.value)}
                        />
                    )}
                </WebsiteEditorCard>
                <WebsiteEditorCard title='Setup your tour' className='setupYourTourTitle'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: setResponsiveView ? 'column' : 'row' }}>
                        <Body2
                            color={SystemColors.PRIMARY_TEXT_COLOR}
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            Add your tour dates to make your tour website.
                        </Body2>

                        {tourDates.length !== 0 && renderAddTourDateButton()}
                    </div>
                    {!tourDates.length &&
                        <div className='tourDateContainer' style={websiteSubSectionContainer}>
                            <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>
                                You don't have any events added yet
                            </Body2>
                            <div style={{ ...tourDateAddUploadContainer, flexDirection: setResponsiveView ? 'column' : 'row' }}>
                                {renderAddTourDateButton()}
                                {renderUploadCsvButton()}
                            </div>

                            {errorCsv &&
                                <div style={{ marginTop: 10 }}>
                                    <div style={{ color: 'red' }}>{errorCsv}</div>
                                </div>
                            }
                        </div>
                    }

                    {renderTourDateList()}


                </WebsiteEditorCard>
                <WebsiteEditorCard title='Additional Options' className="additionalOptionsTour">
                    <div className={classes.secondaryTextSection}>
                        <Switch
                            checked={hidePastTourDates}
                            onChange={(e: any) => setHidePastTourDates(e.target.checked)}
                            className={clsx(hidePastTourDates && classes.switchEnabled)}
                        />
                        <StyledLabel fontSize={16}>
                            Hide past dates from your tour page
                        </StyledLabel>
                    </div>

                    <Container
                        display='flex'
                        flexDirection='row'
                        alignItems={'center'}
                        justifyContent='flex-start'
                        onClick={(() => !isProUser && handleOpenUpgradeToProModal())}
                    >
                        <Container alignItems='center' justifyContent='center'>
                            <Switch
                                disabled={!isProUser}
                                onChange={handleRemoveSymphonyBrand}
                                checked={!removeSymphonyBrand}
                                className={clsx(!removeSymphonyBrand && classes.switchEnabled)}
                            />
                        </Container>
                        <Container display='flex' gap="4px" alignItems="center">
                            <StyledLabel fontSize={16}>Show Symphony Logo</StyledLabel>
                            {!isProUser && (
                                <GemIcon color="purple" width={18} height={18} />
                            )}
                        </Container>
                    </Container>
                    {!removeSymphonyBrand && (
                        <Container
                            display='flex'
                            alignItems='center'
                            gap='16px'
                            borderRadius='8px'
                            padding='16px'
                            justifyContent='center'
                            width='100%'
                            backgroundColor='#8C8C8C'
                        >
                            <StyledLabel fontSize={16} color='#ffffff'>
                                Powered by
                            </StyledLabel>
                            <Container
                                backgroundColor='#000000'
                                padding='4px 12px'
                                borderRadius='4px'
                            >
                                <Image
                                    width={130}
                                    height={17}
                                    alt='symphony-logo.svg'
                                    src={SymphonyLogo}
                                />
                            </Container>
                        </Container>
                    )}
                    <Container
                        display='flex'
                        flexDirection='row'
                        alignItems={'center'}
                        justifyContent='flex-start'
                        onClick={(() => !isProUser && handleOpenUpgradeToProModal())}
                    >
                        <Container alignItems='center' justifyContent='center'>
                            <Switch
                                disabled={!isProUser}
                                onChange={(e: any) => setAddAffiliateLinkToLogo(e.target.checked)}
                                className={clsx(addAffiliateLinkToLogo && classes.switchEnabled)}
                                checked={addAffiliateLinkToLogo}
                            />
                        </Container>
                        <StyledLabel fontSize={16}>Add your Referral Link to Symphony Logo</StyledLabel>
                    </Container>
                    {(
                        <div className={classes.banner}>
                            <Container display="flex" gap="8px" flexDirection="column" alignItems="flex-start" justifyContent="center">
                                <Container display="flex" flexDirection="row" gap={"8px"} alignItems="center">
                                    <img className="w-8 h-8" src={DollarSignIcon} />
                                    <Subtitle2>Get paid when people sign up using your Referral Link.</Subtitle2>
                                </Container>
                                <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>The Symphony referral program gives you a 50% commission for every new Symphony Pro subscriber you drive. Earn up to $120 / year.</Body2>
                                <LinkButton
                                    onClick={() => {
                                        Intercom.openArticleWithId('7002468')
                                    }}
                                    text={`Learn More >`}></LinkButton>
                            </Container>
                        </div>
                    )}
                </WebsiteEditorCard>

            </div>

            {renderTourDateModal()}
        </div>
    )
}