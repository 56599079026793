import { Theme, makeStyles } from "@material-ui/core/styles";
import { SystemColors } from "types/globalStyles";

export const useStyles = makeStyles((_theme: Theme) => ({
    websitePreviewComponentV2: {
        position: "sticky",
        width: "100%",
        aspectRatio: '1/2',
        overflowY: "scroll",
        overflowX: "hidden",
        scrollbarWidth: 'none',
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
    },
    websitePreviewComponentFullView: {
        width: "100%",
        height: "fit-content",
        overflowY: "scroll",
        overflowX: "hidden",
        scrollbarWidth: 'none',
        textAlign: "center",
        zIndex: 100,
    },
    backgroundContainer: {
        position: 'absolute',
        width: '100%',
        height: '101%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    youtubeEmbeddedContainer: {
        width: "100%",
        aspectRatio: 1.5,
        "& article": {
            width: "100%",
            height: "100%",
        },
    },
    infoTextContainer: {
        width: "100%",
    },
    vinylPlayerMainHeader: {
        overflow: "hidden",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
    playMedia: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: '4%',
        width: "90%",
        margin: "0 auto",
        borderRadius: '4px',
        border: '1px solid rgba(0, 0, 0, 0.24)',
        gap: '2%',
    },
    artistInfo: {
        marginBottom: '2%',
        textWrap: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    vinylPlayerPlayButton: {
        width: '15%',
        backgroundColor: SystemColors.PAPER_DARK_CONTAINER,
        aspectRatio: 1,
        cursor: "pointer",
        borderRadius: 999,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "& .svg": {
            marginLeft: '2%',
        },
    },
    audioPlayerHolder: {
        display: 'flex',
        justifyContent: 'center',
        gap: '4%',
        width: '85%',
        flexDirection: 'column',
    },
    audioPlayerIcon: {
        height: '50%',
        width: '50%',
    },
    socialMediaIconsContainer: {
        marginBottom: '2%',
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
        width: "fit-content",
    },
    externalLinksContainer: {
        display: "flex",
        flexDirection: "column",
        padding: '4% 0px',
    },
    stackedExternalLinks: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: '4%',
        borderTop: '0.25px solid',
    },
    traditionalExternalLinks: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: '4% 8%',
        width: "100%",
        borderTop: '0.25px solid',
    },
    traditionalButton: {
        padding: "0px 10px",
        borderRadius: 4,
        width: '30%',
        aspectRatio: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    externalLinksBoxesContainer: {
        display: "flex",
        columnGap: '5%',
        flexWrap: "wrap",
        justifyContent: "center",
        padding: '0px 5%',
    },
    externalLinkBox: {
        display: "flex",
        borderRadius: 8,
        padding: '5%',
        margin: '2% 0px',
        width: '47%',
        aspectRatio: '1/0.6',
    },
    externalLinkImage: {
        display: "flex",
        alignSelf: "center",
    },
    stackedImageIcons: {
        alignSelf: "center",
        maxWidth: '40%',
        maxHeight: "100%",
    },
    listenButton: {
        borderRadius: 100,
        fontSize: 10,
        padding: "4px 13px",
        marginLeft: "auto",
    },
    phoneHeader: {
        backgroundColor: "black",
        borderRadius: 100,
        width: '27%',
        aspectRatio: 3.3,
        marginTop: '5%',
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: '3%',
    },
    vinylPlayerContainer: {
        paddingLeft: 16,
        paddingRight: 16,
        marginBottom: '18%',
    },
    fullImageInfoTextContainer: {
        marginTop: "auto",
        textAlign: "left",
        padding: "0px 5%",
        display: 'flex',
        flexDirection: 'column',
    },
    fullImageRecordText: {
        fontWeight: 500,
        lineHeight: "normal",
    },
    fullImageArtistText: {
        lineHeight: "normal",
    },
    oneFieldImage: {
        width: '50%',
        aspectRatio: 1,
        borderRadius: '50%',
        objectFit: "cover",
    },
    basicCollectorImage: {
        width: '100%',
        aspectRatio: 2,
        borderRadius: 8,
        objectFit: "cover",
    },
    tourBasicCollectorImage: {
        aspectRatio: 1,
        objectFit: "contain",
    },
    fullImageCollectorImage: {
        width: '100%',
        height: '100%',
        borderRadius: 8,
    },
    submitButton: {
        borderRadius: 99,
        padding: '3% 4%',
        marginTop: '3%',
    },
    emailPhoneContainer: {
        display: "flex",
        flexDirection: "column",
        gap: '4%',
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    emailNumberSelector: {
        display: "flex",
        gap: '3%',
        width: "60%",
        aspectRatio: 5,
        backgroundColor: SystemColors.PAPER_CONTAINER,
        borderRadius: 3,
        "& .selected": {
            color: "white",
            backgroundColor: SystemColors.PAPER_DARK_CONTAINER,
        },
    },
    emailPhoneButton: {
        width: "50%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 3,
        padding: '2% 0px',
        color: 'black',
        cursor: "pointer",
    },
    fieldContainer: {
        aspectRatio: 7,
        padding: '4% 6%',
        fontFamily: 'DIN',
        borderWidth: 1,
        borderStyle: 'solid',
        textAlign: 'left',
        display: 'flex',
        gap: 10,
        margin: '2% 0px',
        alignItems: 'center',
        width: "100%",
    },
    lightLink: {
        width: 12,
        height: 12,
        "& path": {
            fill: "white",
        }
    },
    darkLink: {
        minWidth: 12,
        minHeight: 12,
        maxWidth: 12,
        maxHeight: 12,
        "& path": {
            fill: "black",
        }
    },
    streamingServiceName: {
        fontWeight: 500,
        overflow: "hidden",
        textOverflow: "ellipsis",
        textWrap: "nowrap",
        lineHeight: "normal",
    },
    fullImageContainer: {
        width: "100%",
        aspectRatio: 0.9,
    },
    fullImageBackground: {
        height: '100%',
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },

    // TOUR CONNER
    tourDateRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,
        borderRadius: 8,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        padding: 8,
    },
    tourDatePreviewContainer: {
        paddingLeft: 8,
        paddingRight: 16,
        borderRight: '1px solid rgba(255, 255, 255, 0.2)',
    },
    tourDateDay: {
        fontSize: 14,
    },
    tourDateMonth: {
        fontSize: 8,
    },
    tourDateCity: {
        fontSize: 15,
    },
    tourDatePlace: {
        fontSize: 12,
    },
    stackedTourDateText: {
        fontSize: 16,
    },
    tourDateBuyButton: {
        fontSize: 15,
        padding: '4px 16px',
        borderRadius: 100,
        whiteSpace: 'nowrap',
        maxWidth: 120,
        // border: '1px solid rgba(255, 255, 255, 0.75)',
    },
    tourDateDetailContainer: {
        marginRight: 'auto',
        textAlign: 'left',
        paddingRight: 5
    },

    tourDateRowStackedPreview: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        marginBottom: 16,
    },
    tourDatePreviewStackedContainer: {
        display: 'flex',
        gap: 8,
        justifyContent: 'center',
        alignItems: 'center',
    },
    tourDateDetailStackedContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    tourDateBuyButtonStacked: {
        fontSize: 16,
        padding: '8px 24px',
        borderRadius: 100,
        whiteSpace: 'nowrap',
        maxWidth: 120,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
    },
}));
