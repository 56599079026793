import { BrandContent, CurrentBrand } from "types/global"
import { WEBSITE_TYPES, WebsiteType } from "../../types"

export const formatDataToPublish = (data: any, brandContent: BrandContent, currentBrand?: CurrentBrand) => {
  const {
    bgType,
    bgValue,
    embedYt,
    enableSocialIcons,
    fields,
    fontThemeStyle,
    mainColor,
    secondaryColor,
    selectedPreviewTrack,
    submitButtonText,
    showSecondaryLine,
    streamingLinks,
    styleType,
    subtitle,
    themeStyle,
    fullVideoUrl,
    titleText,
    youtubeID,
    youtubeVideoLink,
    previewUrl,
    customThumbnailViewStyle,
    hidePastTourDates
  } = data

  // using let to make it mutable
  let {
    tourDates,
  } = data;

  const getDefaultTitle = (brandContent: BrandContent, currentBrand?: CurrentBrand) => {
    switch (brandContent.type) {
      case WEBSITE_TYPES.DATA_COLLECTOR:
        return currentBrand?.name || 'Listen to'
      case WEBSITE_TYPES.TOUR:
        return currentBrand?.name || 'Listen to'
      default:
        return 'Listen to'
    }
  }

  const getBrandContentName = (brandContent: BrandContent) => {
    switch (brandContent.type) {
      case WEBSITE_TYPES.DATA_COLLECTOR:
        return titleText
      case WEBSITE_TYPES.TOUR:
        return titleText
      default:
        return brandContent?.name || titleText
    }
  }

  const tourDatesDataTransform = (tourDates: any) => {
    if (!tourDates && !tourDates.length) {
      return null;
    }

    tourDates = tourDates.map((td: any) => {
      const tourDate = {
        city: td.city,
        country: td.country,
        date: td.date,
        displayName: td.displayName,
        isEnableEmailNumberCollection: td.isEnableEmailNumberCollection,
        isPreSalePeriod: td.isPreSalePeriod,
        preSaleAction: td.preSaleAction,
        isSoldOut: td.isSoldOut,
        preSaleDate: td.preSaleDate,
        preSaleButtonText: td.preSaleButtonText,
        preSaleCustomLink: td.preSaleCustomLink,
        region: td.region,
        ticketUrl: td.ticketUrl,
        venue: td.venue,
        buttonLinkText: td.buttonLinkText,
      }

      return tourDate
    })

    return tourDates
  }

  const brandContentType: WebsiteType = brandContent?.type as WebsiteType || WEBSITE_TYPES.DATA_COLLECTOR
  const defaultTitle = getDefaultTitle(brandContent, currentBrand);
  const brandContentName = getBrandContentName(brandContent);

  let external_links;
  if (streamingLinks) {
    external_links = Object.entries(streamingLinks).map(([key, value]: [string, any]) => ({
      name: key === 'custom_link' ? value.title : key,
      url: value.url,
      enabled: value.enabled,
      type: value.type || key,
    }))
  }

  tourDates = tourDatesDataTransform(tourDates);

  return {
    id: brandContent?.id || undefined,
    type: brandContentType,
    content_metadata: {
      ...brandContent.content_metadata,
      name: brandContentName,
      external_links,
      website: {
        fields,
        tourDates,
        hidePastTourDates,
        post_subscribe_message: "Thanks for subscribing! Stay tuned.",
        style: {
          titleText: titleText.length ? titleText : defaultTitle,
          showSecondaryLine,
          subtitle,
          secondaryColor,
          primaryColor: mainColor,
          submitButtonText,
          previewUrl: brandContent?.content_metadata.thumbnail_url || previewUrl || undefined,
          bgType,
          bgColor: bgValue?.color,
          font: fontThemeStyle,
          template: themeStyle,
          youtubeEmbed: embedYt ? { id: youtubeID, url: youtubeVideoLink } : null,
          buttonConfig: styleType,
          enableSocialIcons,
          selectedPreviewTrack,
          full_video_url: fullVideoUrl,
          customThumbnailViewStyle: customThumbnailViewStyle || 'cover',
        }
      }
    },
    brand_id: brandContent?.brand_id,
    slug: brandContent?.slug?.trim() || '',
    name: brandContentName,
    url: brandContent?.url,
  }
}