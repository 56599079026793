import {
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';
import {
  AlbumTrack,
  SocialMediaType,
  StreamingLinkTypeOptions,
  StreamingService,
  WebsiteLink,
  WebsiteLinks,
  bgValueType,
  socialMediaIconsInit,
  socialMediaTitleDict,
  streamingTitleDict,
  websiteLinkInitObj,
  dataCollectorFieldsInit,
  ThemeStyleKey,
  THEME_STYLES,
} from '../utils';
import { capitalizeFirstLetter } from 'helpers/General';
import { BrandContent, ExternalLink, Field, RecordSelected } from 'types/global';
import { deepCopy } from 'utils';
import { WebsiteType, WEBSITE_TYPES, RECORD_STREAMS_TYPES } from 'pages/post-auth/Website/types';
import { WebsiteDataType } from '../../WebsiteDashboardv2/types';
import { filterOutLinks } from 'helpers/StreamingMappings';

const { RECORD_STREAMS, DATA_COLLECTOR, TOUR } = WEBSITE_TYPES;
const { BASIC } = THEME_STYLES

interface Context {
  subtitle: string | undefined;
  streamingLinks: WebsiteLinks | undefined;
  socialMediaIcons: WebsiteLinks | undefined;
  titleText: string | undefined;
  showSecondaryLine: boolean | undefined;
  customLinksAdded: WebsiteLink[];
  isNewLink: boolean;
  customLinkOptions: WebsiteLink[];
  enableSocialIcons: boolean | undefined;
  removeSymphonyBrand: boolean | undefined;
  addAffiliateLinkToLogo: boolean;
  fields: Field[] | undefined;
  submitButtonText?: string | undefined;
  themeStyle: ThemeStyleKey | undefined;
  fullVideoUrl: string | undefined;
  styleType: string | undefined;
  fontThemeStyle: string | undefined;
  embedYt: boolean | undefined;
  youtubeVideoLink: string | undefined;
  mainColor: string | undefined;
  recordSelected: RecordSelected | undefined;
  secondaryColor: string | undefined;
  bgValue: bgValueType | undefined;
  bgType: string | undefined;
  youtubeID: string | undefined;
  isProject: boolean | undefined;
  showRecordSelector: boolean | undefined;
  selectedPreviewTrack: AlbumTrack | null;
  metrics: WebsiteDataType | null;
  customThumbnailUrl: string | null;
  customThumbnailViewStyle: 'cover' | 'contain';

  tourExternalUrl: string | undefined;
  tourDates: any[];
  hidePastTourDates: boolean;

  setCustomThumbnailUrl: Dispatch<SetStateAction<string | null>>;
  setCustomThumbnailViewStyle: Dispatch<SetStateAction<'cover' | 'contain'>>;
  setIsProject: Dispatch<SetStateAction<boolean | undefined>>;
  setShowRecordSelector: Dispatch<SetStateAction<boolean | undefined>>;
  setSelectedPreviewTrack: Dispatch<SetStateAction<AlbumTrack | null>>;
  setYoutubeEmbedId: Dispatch<SetStateAction<string | undefined>>;
  setThemeStyle: Dispatch<SetStateAction<ThemeStyleKey | undefined>>;
  setFullVideoUrl: Dispatch<SetStateAction<string | undefined>>;
  setStyleType: Dispatch<SetStateAction<string | undefined>>;
  setFontThemeStyle: Dispatch<SetStateAction<string | undefined>>;
  setEmbedYt: Dispatch<SetStateAction<boolean | undefined>>;
  setYTVideoLink: Dispatch<SetStateAction<string | undefined>>;
  setMainColor: Dispatch<SetStateAction<string | undefined>>;
  setRecordSelected: Dispatch<SetStateAction<RecordSelected | undefined>>;
  setSecondaryColor: Dispatch<SetStateAction<string | undefined>>;
  setBgValue: Dispatch<SetStateAction<bgValueType | undefined>>;
  setBgType: Dispatch<SetStateAction<string | undefined>>;
  setSubmitButtonText: Dispatch<SetStateAction<string | undefined>>;
  setFields: Dispatch<SetStateAction<Field[] | undefined>>;
  setRemoveSymphonyBrand: Dispatch<SetStateAction<boolean | undefined>>;
  setAddAffiliateLinkToLogo: Dispatch<SetStateAction<boolean>>;
  setEnableSocialIcons: Dispatch<SetStateAction<boolean | undefined>>;
  setCustomLinkOptions: Dispatch<SetStateAction<WebsiteLink[]>>;
  setIsNewLink: Dispatch<SetStateAction<boolean>>;
  setCustomLinksAdded: Dispatch<SetStateAction<WebsiteLink[]>>;
  setShowSecondaryLine: Dispatch<SetStateAction<boolean | undefined>>;
  setTitleText: Dispatch<SetStateAction<string | undefined>>;
  setStreamingLinks: Dispatch<SetStateAction<WebsiteLinks | undefined>>;
  setSubtitle: Dispatch<SetStateAction<string | undefined>>;
  setSocialMediaIcons: Dispatch<SetStateAction<WebsiteLinks | undefined>>;
  setMetrics: Dispatch<SetStateAction<WebsiteDataType | null>>;
  
  setTourExternalUrl: Dispatch<SetStateAction<string | undefined>>;
  setTourDates: Dispatch<SetStateAction<any[]>>;
  setHidePastTourDates: Dispatch<SetStateAction<boolean>>;
}

export const WebsiteContext = createContext<Context>({
  subtitle: '',
  streamingLinks: {},
  socialMediaIcons: {},
  titleText: '',
  showSecondaryLine: false,
  customLinksAdded: [],
  isNewLink: false,
  customLinkOptions: [],
  enableSocialIcons: false,
  removeSymphonyBrand: false,
  addAffiliateLinkToLogo: true,
  fields: [],
  submitButtonText: '',
  themeStyle: undefined,
  fullVideoUrl: undefined,
  styleType: undefined,
  fontThemeStyle: undefined,
  embedYt: false,
  youtubeVideoLink: '',
  mainColor: '',
  recordSelected: undefined,
  secondaryColor: '',
  bgValue: { color: '', artwork: 0 },
  bgType: '',
  youtubeID: '',
  isProject: false,
  showRecordSelector: false,
  selectedPreviewTrack: null,
  metrics: null,
  customThumbnailUrl: null,
  customThumbnailViewStyle: 'cover',
  
  tourExternalUrl: '',
  tourDates: [],
  hidePastTourDates: false,

  setCustomThumbnailUrl: () => { },
  setCustomThumbnailViewStyle: () => { },
  setIsProject: () => { },
  setShowRecordSelector: () => { },
  setSelectedPreviewTrack: () => { },
  setYoutubeEmbedId: () => { },
  setThemeStyle: () => { },
  setFullVideoUrl: () => { },
  setStyleType: () => { },
  setFontThemeStyle: () => { },
  setEmbedYt: () => { },
  setYTVideoLink: () => { },
  setMainColor: () => { },
  setRecordSelected: () => { },
  setSecondaryColor: () => { },
  setBgValue: () => { },
  setBgType: () => { },
  setSubmitButtonText: () => { },
  setFields: () => { },
  setRemoveSymphonyBrand: () => { },
  setAddAffiliateLinkToLogo: () => { },
  setEnableSocialIcons: () => { },
  setCustomLinkOptions: () => { },
  setIsNewLink: () => { },
  setCustomLinksAdded: () => { },
  setShowSecondaryLine: () => { },
  setTitleText: () => { },
  setStreamingLinks: () => { },
  setSubtitle: () => { },
  setSocialMediaIcons: () => { },
  setMetrics: () => { },

  setTourExternalUrl: () => { },
  setTourDates: () => { },
  setHidePastTourDates: () => { },
});

interface Props {
  children: ReactNode[] | ReactNode;
  brandContent?: BrandContent;
  websiteType: WebsiteType
}

export const WebsiteProvider: FunctionComponent<Props> = ({
  children,
  brandContent,
  websiteType,
}) => {
  const [subtitle, setSubtitle] = useState<string>();
  const [streamingLinks, setStreamingLinks] = useState<WebsiteLinks>();
  const [socialMediaIcons, setSocialMediaIcons] = useState<WebsiteLinks>();
  const [titleText, setTitleText] = useState<string>();
  const [showSecondaryLine, setShowSecondaryLine] = useState<boolean>(); // TODO: needs to come from current brand
  const [customLinksAdded, setCustomLinksAdded] = useState<WebsiteLink[]>([]);
  const [isNewLink, setIsNewLink] = useState<boolean>(false);
  const [customLinkOptions, setCustomLinkOptions] = useState<WebsiteLink[]>([]);
  const [enableSocialIcons, setEnableSocialIcons] = useState<boolean>();
  const [removeSymphonyBrand, setRemoveSymphonyBrand] = useState<boolean>();
  const [addAffiliateLinkToLogo, setAddAffiliateLinkToLogo] = useState<boolean>(true);
  const [smIconBackgroundColor, setSmIconBackgroundColor] = useState('');
  const [fields, setFields] = useState<Field[]>();
  const [submitButtonText, setSubmitButtonText] = useState<string>();
  const [themeStyle, setThemeStyle] = useState<ThemeStyleKey>();
  const [fullVideoUrl, setFullVideoUrl] = useState<string>();
  const [styleType, setStyleType] = useState<string>();
  const [fontThemeStyle, setFontThemeStyle] = useState<string>();
  const [embedYt, setEmbedYt] = useState<boolean>();
  const [youtubeVideoLink, setYTVideoLink] = useState<string>();
  const [mainColor, setMainColor] = useState<string>();
  const [recordSelected, setRecordSelected] = useState<RecordSelected>();
  const [secondaryColor, setSecondaryColor] = useState<string>();
  const [bgValue, setBgValue] = useState<bgValueType>();
  const [bgType, setBgType] = useState<string>();
  const [youtubeID, setYoutubeEmbedId] = useState<string>();
  const [isProject, setIsProject] = useState<boolean>()
  const [showRecordSelector, setShowRecordSelector] = useState<boolean>();
  const [selectedPreviewTrack, setSelectedPreviewTrack] = useState<AlbumTrack | null>(null);
  const [metrics, setMetrics] = useState<WebsiteDataType | null>(null)
  const [customThumbnailUrl, setCustomThumbnailUrl] = useState<string | null>(null)
  const [customThumbnailViewStyle, setCustomThumbnailViewStyle] = useState<'cover' | 'contain'>('cover')

  const [tourExternalUrl, setTourExternalUrl] = useState<string>()
  const [tourDates, setTourDates] = useState<any[]>([])
  const [hidePastTourDates, setHidePastTourDates] = useState<boolean>(false)

  useEffect(() => {
    // NOTE: THIS CURRENTLY ISN'T BEING USED
    let newSocialMediaIcons = deepCopy(socialMediaIcons) as WebsiteLinks;

    for (const key in newSocialMediaIcons) {
      if (newSocialMediaIcons.hasOwnProperty(key)) {
        // newSocialMediaIcons[key].backgroundColor = smIconBackgroundColor;
      }
    }

    setSocialMediaIcons(newSocialMediaIcons);
  }, [smIconBackgroundColor]);

  useEffect(() => {
    if (streamingLinks) {
      const customLinkOptions = StreamingLinkTypeOptions.filter((type) => {
        const isAlreadyAdded = Object.values(streamingLinks).find(
          (link) => link.key === type.key
        );
        return !isAlreadyAdded && filterOutLinks(type.key);
      });
      setCustomLinkOptions(customLinkOptions);
    }
  }, [streamingLinks]);

  useEffect(() => {
    setInitialStyles();
  }, []);

  const setInitialStyles = () => {
    const styles = brandContent?.content_metadata?.website?.style
      || brandContent?.content_metadata?.presave?.style
      || {};
    const preset = brandContent?.brand_preset;
    const affiliateToLogo = preset?.addAffiliateLinkToLogo ?? true;
    const backgroundType = styles.bgType
      || brandContent?.content_metadata.thumbnail_url ? 'artwork' : 'color';
    const backgroundValue = {
      color: styles.bgColor || '#0B0113',
      artwork: backgroundType === 'artwork' ? 1 : 0,
    };

    const titleOptions = ['Sign up for exclusive updates', 'Join the community', 'Unlock behind-the-scenes content'];
    // TODO: Update the default title based on the website type
    const defaultTitle = websiteType === TOUR ? '2024 Tour' : '';

    setTitleText(styles.titleText || defaultTitle);
    setSubtitle(styles.subtitle || '');
    setShowSecondaryLine(Boolean(styles.showSecondaryLine));
    setBgType(backgroundType);
    setBgValue(backgroundValue);
    setSecondaryColor(styles.secondaryColor || '#000000');
    setMainColor(styles.primaryColor || '#7C18F7');
    setFontThemeStyle(styles.font || 'font1');
    setRemoveSymphonyBrand(Boolean(preset?.removeSymphonyBrand))
    setAddAffiliateLinkToLogo(Boolean(affiliateToLogo))

    if (websiteType === RECORD_STREAMS) {
      let template = styles.template || 'art'
      if (!styles.template && brandContent?.content_metadata.type === RECORD_STREAMS_TYPES.PLAYLIST) template = 'art'
      const external_links = brandContent?.content_metadata.external_links
        || [{ name: 'spotify', url: brandContent?.content_metadata.record_url || '', enabled: true, type: 'spotify' }]
      const recordInitObj: RecordSelected = {
        full_info: true,
        ...brandContent!.content_metadata,
        slug: brandContent?.slug || '',
        url: brandContent?.url || '',
        id: brandContent?.id || 0,
        total_tracks: brandContent?.content_metadata.total_tracks || 0,
        spotify_release_type: brandContent?.content_metadata.spotify_release_type || '',
        release_date: brandContent?.content_metadata.release_date || '',
        external_links,
      }
      const isProject = recordInitObj.tracks && recordInitObj.tracks?.length > 0;

      setStyleType(styles.buttonConfig || 'boxes');
      setThemeStyle(template);
      setFullVideoUrl(styles.full_video_url);
      setRecordSelected(recordInitObj);
      setIsProject(isProject);
      setEmbedYt(Boolean(styles.youtubeEmbed?.url && styles.youtubeEmbed?.id));
      setYTVideoLink(styles.youtubeEmbed?.url || '');
      setYoutubeEmbedId(styles.youtubeEmbed?.id || '');
      setEnableSocialIcons(Boolean(preset?.enableSocialIcons));
      initSocialMediaIcons();
      initExternalLinks();
    } else if (websiteType === DATA_COLLECTOR) {
      setFullVideoUrl(styles.full_video_url);
      setCustomThumbnailUrl(styles.thumbnail_url);
      setSubmitButtonText(styles.submit_button_text || 'Subscribe');
      setFields(brandContent?.content_metadata?.website?.fields || dataCollectorFieldsInit);
      setThemeStyle(styles.template || BASIC);
    } else if (websiteType === TOUR) {
      setStyleType(styles.buttonConfig || 'boxes');
      setFullVideoUrl(styles.full_video_url);
      setCustomThumbnailUrl(styles.thumbnail_url);
      setSubmitButtonText(styles.submit_button_text || 'Subscribe');
      setTourDates(brandContent?.content_metadata?.website?.tourDates || []);
      setHidePastTourDates(brandContent?.content_metadata?.website?.hidePastTourDates || false);
      setThemeStyle(styles.template || BASIC);
    } else { // TODO: currently this is the same as DATA_COLLECTOR
      setFullVideoUrl(styles.full_video_url);
      setCustomThumbnailUrl(styles.thumbnail_url);
      setSubmitButtonText(styles.submit_button_text || 'Subscribe');
      setFields(brandContent?.content_metadata?.website?.fields || dataCollectorFieldsInit);
      setThemeStyle(styles.template || BASIC);
    }
  }

  const initSocialMediaIcons = () => {
    const socialIcons = brandContent?.brand_preset?.socialIcons || [];
    if (!socialMediaIcons && socialIcons.length) {
      const newSocialIcons: WebsiteLinks = {};
      for (const socialIcon of socialIcons) {
        newSocialIcons[socialIcon.key] = {
          ...socialIcon,
          title: socialMediaTitleDict[socialIcon.key as SocialMediaType],
        };
      }
      setSocialMediaIcons(newSocialIcons);
    } else if (!socialMediaIcons) {
      setSocialMediaIcons(socialMediaIconsInit);
    }
  }

  const initExternalLinks = () => {
    let externalLinks: ExternalLink[] = brandContent?.content_metadata?.external_links || [];

    if (brandContent?.content_metadata.type === RECORD_STREAMS_TYPES.PLAYLIST && !externalLinks.length) {
      externalLinks.push({ name: 'spotify', url: brandContent?.content_metadata.record_url || '', enabled: true, type: 'spotify' });
    }
    if (!streamingLinks && externalLinks.length) {
      const newStreamingLinks: WebsiteLinks = {};
      for (const link of externalLinks) {
        const newWebsiteLink = {
          ...websiteLinkInitObj,
          ...{
            title:
              streamingTitleDict[link.name as StreamingService] ||
              capitalizeFirstLetter(link.name),
            key: link.name,
            type: link.type,
            url: link.url,
            enabled: (
              brandContent?.content_metadata &&
              'website' in brandContent?.content_metadata &&
              'enabled' in link
            ) ? link.enabled : true,
          },
        };
        if (link.type === 'custom' || link.type === 'custom_link') {
          setCustomLinksAdded((prev) => [...prev, newWebsiteLink]);
        }
        newStreamingLinks[link.name] = newWebsiteLink;
      }
      setStreamingLinks(newStreamingLinks);
    }
  }

  const context = {
    subtitle,
    streamingLinks,
    socialMediaIcons,
    titleText,
    showSecondaryLine,
    customLinksAdded,
    isNewLink,
    customLinkOptions,
    enableSocialIcons,
    removeSymphonyBrand,
    addAffiliateLinkToLogo,
    fields,
    submitButtonText,
    themeStyle,
    fullVideoUrl,
    styleType,
    fontThemeStyle,
    embedYt,
    youtubeVideoLink,
    mainColor,
    recordSelected,
    secondaryColor,
    bgValue,
    bgType,
    youtubeID,
    isProject,
    showRecordSelector,
    selectedPreviewTrack,
    metrics,
    customThumbnailUrl,
    customThumbnailViewStyle,
    tourExternalUrl,
    tourDates,
    hidePastTourDates,

    setCustomThumbnailUrl,
    setCustomThumbnailViewStyle,
    setIsProject,
    setShowRecordSelector,
    setSelectedPreviewTrack,
    setYoutubeEmbedId,
    setThemeStyle,
    setFullVideoUrl,
    setStyleType,
    setFontThemeStyle,
    setEmbedYt,
    setYTVideoLink,
    setMainColor,
    setRecordSelected,
    setSecondaryColor,
    setBgValue,
    setBgType,
    setSubmitButtonText,
    setFields,
    setRemoveSymphonyBrand,
    setAddAffiliateLinkToLogo,
    setEnableSocialIcons,
    setCustomLinkOptions,
    setIsNewLink,
    setCustomLinksAdded,
    setShowSecondaryLine,
    setTitleText,
    setStreamingLinks,
    setSubtitle,
    setSocialMediaIcons,
    setMetrics,
    setTourExternalUrl,
    setTourDates,
    setHidePastTourDates,
  };

  return (
    <WebsiteContext.Provider value={context}>
      {children}
    </WebsiteContext.Provider>
  )
};
